import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

// mui icons
import { ReactComponent as ArrowDown } from '../../images/icon/arrow-down-white.svg'

// mui custom style
import { useStyles } from '../../themes/index'
import useAuthHook from '../../hooks/useAuth'
import { PAGE_PATH_NAME } from '../../constants/constants'
import { authInfoSlice } from '../../state/slice/authInfoSlice'

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // account info email
  name: string
  picture: string
}

const Account = React.memo<Props>(({ name, picture }): JSX.Element => {
  // const { t } = useSwitchLocaleLanguage();
  // common style
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const { AuthInfoReset } = useAuthHook()
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const menuId = isMenuOpen ? 'simple-popper' : undefined
const {
    saveLoggedIn
  } = authInfoSlice.actions
  const dispatch = useDispatch();
  const handleLogout = () => {
    AuthInfoReset();
    dispatch(saveLoggedIn(false));
    navigate(PAGE_PATH_NAME.LOGIN);
    // window.location.reload();
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton
        size='large'
        edge='end'
        aria-label='account of current user'
        aria-controls={menuId}
        aria-describedby={menuId}
        aria-haspopup='true'
        onClick={handleProfileMenuOpen}
        color='inherit'
        disableRipple
        sx={{
          padding: '0',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        {picture ? (
          <Avatar className={classes.avatar} src={picture} alt={name} />
        ) : (
          <Avatar className={classes.avatar}>{name[0]}</Avatar>
        )}
        <ArrowDown className={classes.avatarArrow} />
      </IconButton>
      <Popper
        placement='bottom-end'
        id={menuId}
        open={isMenuOpen}
        anchorEl={anchorEl}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [16, 4]
              }
            }
          ]
        }}
        className={classes.accountMenu}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <List>
            <ListItemButton onClick={handleLogout}>Logout</ListItemButton>
          </List>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
})

export default Account
