import React from 'react'
import { Box } from '@mui/material'
import useStyles from './style'

export interface FormErrorMessageProps {
  message?: string
  isError?: boolean
  children: React.ReactNode
}
const FormErrorMessage = ({
  message,
  isError,
  children
}: FormErrorMessageProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.formError}>
      {isError && <span className={classes.formError_message}>{message}</span>}
      {children}
    </Box>
  )
}

export default FormErrorMessage
