import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/session';
import localStorage from 'redux-persist/es/storage';
import { loginInfoSlice } from './slice/loginInfoSlice';
import { WEB_STORAGE } from '../constants/constants';
import apiSlice from './api/apiSlice';
import { authInfoSlice } from './slice/authInfoSlice';

const persistConfig = {
  key: 'root', // storeに保存されるkey名
  storage: localStorage, // storageに保存する方法を指定
  whitelist: [WEB_STORAGE.AUTH_INFO], // storageには`iotLoginInfo`のみ保存。他はstoreに保存
  version: 1, // マイグレーション用に指定しておいた方が良い
  debug: true,
};

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [WEB_STORAGE.LOGIN_INFO]: loginInfoSlice.reducer,
  [WEB_STORAGE.AUTH_INFO]: authInfoSlice.reducer,
});

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, reducers);

// https://github.com/rt2zz/redux-persist/issues/988
// こちらを参考に「persist」キーの「serializable」のチェックを無視
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
