/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles'
import { whiteColor } from '../../../themes'
import { ptColors } from '../../../themes/settings/colors'

const useCreateNotificationStyles = makeStyles(() => ({
  submitBtn: {
    fontSize: '14px',
    height: '38px',
    fontWeight: 'normal',
    '&:hover': {
      borderColor: '#0078AB',
      backgroundColor: '#006D9C'
    },
    '&:focus': {
      outline: '5px solid #CCEBF9',
    }
  },
  createNotificationContainer: {
    width: '100%',
    maxWidth: '980px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  cardContainer: {
    width: '100%',
    backgroundColor: 'white',
    padding: '32px 32px 40px',
    borderRadius: '8px',
    border: '0.5px solid #d9d9d9'
  },
  left: {
    width: '50%',
    marginRight: 30
  },
  right: {
    width: '50%'
  },
  actionBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // width: '49%',
    columnGap: 10,
    // marginTop: 12
    margin: '10px auto 10px auto',
    maxWidth: '980px'
  },
  createContainer: {
    maxWidth: '100%',
    // minHeight: 'calc(100vh - 258px)',
    minHeight: '341px',
    backgroundColor: whiteColor,
    border: '1px solid #EAEDED',
    borderRadius: '8px',
    padding: '33px 32px 32px 40px',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 1440px)': {
      minHeight: 'calc(100vh - 247px)'
    },
    '@media screen and (max-width:1336px)': {
      minHeight: 'calc(100vh - 237px)'
    }
  },
  headerText: {
    fontFamily: "'Noto Sans JP'",
    fontSize: 20,
    // color: '#313133',
    fontWeight: 500,
    lineHeight: '27px',
    marginBottom: '22px',
    letterSpacing: '0.13px'
  },
  textTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
    fontFamily: "'Noto Sans JP'",
    marginBottom: '13px'
  },
  headerTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '19px',
    fontFamily: "'Noto Sans JP'"
  },
  formBlock: {
    marginTop: '22px'
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '-10px',
    marginRight: '-10px'
  },
  formCol: {
    width: '33.33%',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  formGroup: {
    height: '38px',
    alignItems: 'center',
    columnGap: '20px'
  },
  formInput: {
    width: '100%'
  },
  formLabel: {
    fontSize: 14,
    color: `${ptColors.ebonyClay}`,
    lineHeight: '19px',
    letterSpacing: '0.09px',
    marginBottom: '6px'
  },
  required: {
    color: `${ptColors.colorRed}`
  },
  checkBoxLabel: {
    marginLeft: '0',
    marginRight: '0',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px !important',
      lineHeight: '19px',
      letterSpacing: '0.09px'
    }
  },
  checkBoxInput: {
    height: '20px',
    padding: '0 10px 0 0',
    '& svg g': {
      stroke: '#ddd'
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg g': {
        stroke: '#9AA5B1'
      }
    },
    '&.Mui-checked': {
      '& svg g': {
        stroke: '#009de1'
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg g': {
          stroke: '#009de1'
        }
      },
    },
    '&.Mui-focusVisible': {
      '& svg g': {
        stroke: '#9AA5B1'
      },
      '&:hover': {
        '& svg g': {
          stroke: '#9AA5B1'
        }
      },
      '&.Mui-focusVisible.Mui-checked': {
        '& svg g': {
          stroke: '#007EB4',
        },
      }
    }
  },
  // customize react select option list start
  reactSelect: {
    '& .react-select__single-value': {
      color: `${ptColors.ebonyClay}`
    },
    '& .react-select__placeholder': {
      color: '#6C7778'
    },
    '& .react-select__indicator': {
      paddingRight: '12px'
    },
    '& .react-select__menu': {
      margin: '0',
      boxShadow: '0px 3px 20px #00000029',
      zIndex: 1000,
      '& .react-select__menu-list': {
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '148px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '& .react-select__option': {
          padding: '10px 12px',
          width: '100%',
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#fafafa',
            color: '#232F3E'
          }
        },
        '& .react-select__option:not(:last-child)': {
          borderBottom: '0.5px solid #ddd'
        }
      }
    }
  },
  selectArrow: {
    marginTop: '2px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5
    }
  },
  dateRangeBox: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer',
    height: '38px',
    '&:hover': {
      border: '1px solid #939598'
    }
  },
  calenderLogo: {
    position: 'absolute',
    right: '10px',
    top: '9px',
    zIndex: '0'
  },
  mailBellInfoBlock: {
    marginTop: 20,
    maxWidth: 780,
    // minHeight: 'calc(100vh - 258px)',
    // minHeight: '341px',
    backgroundColor: whiteColor,
    border: '1px solid #EAEDED',
    borderRadius: '8px',
    padding: '33px 32px 32px 40px',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 1440px)': {
      minHeight: 'calc(100vh - 247px)'
    },
    '@media screen and (max-width:1336px)': {
      minHeight: 'calc(100vh - 237px)'
    }
  },
  mailBlock: {
    marginTop: '31px'
  },
  bellBlock: {
    marginTop: '31px'
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '13px'
  },
  previewBtn: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    color: `#007EB4`,
    cursor: 'pointer',
    '&:hover': {
      color: '#006D9C',
      '&  svg  path': {
        fill: '#006D9C'
      }
    },
    '&:focus': {
      color: '#006D9C',
      outline: 'none',
      '&  svg  path': {
        fill: '#006D9C'
      }
    },
    '&:disabled': {
      color: '#ABB7C5',
      outline: 'none',
      '&  svg  path': {
        fill: '#ABB7C5'
      },
      '&:hover': {
        color: '#ABB7C5',
        '&  svg  path': {
          fill: '#ABB7C5'
        }
      },
    }
  },
  previewText: {
    marginRight: 11
  },
  mb13: {
    marginBottom: '13px'
  },
  draftBtn: {
    color: '#007EB4 !important',
    border: '1px solid #0078AB !important',
    backgroundColor: `${ptColors.white}`,
    fontSize: '14px',
    height: '38px !important',
    fontWeight: 'normal',
    '&:hover': {
      color: '#006D9C !important',
      backgroundColor: '#FFF !important'
    },
    '&:focus': {
      outline: '5px solid #CCEBF9',
    }
  },
  deleteBtn: {
    width: 38,
    height: 38,
    minWidth: 'unset',
    padding: 'unset',
    background: `${ptColors.white}`,
    border: '0.5px solid #d9d9d9',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    boxShadow: '0px 3px 6px #00000029',
    '&:hover': {
      backgroundColor: '#FAFAFA !important',
    },
    '&:focus': {
      outline: '4px solid #CCEBF9',
    }
  },
  deleteAction: {
    position: 'absolute',
    top: 28,
    right: 16,
    display: 'flex',
    alignItems: 'center',
    height: 36,
    padding: '0px 16px',
    backgroundColor: `${ptColors.white}`,
    borderRadius: 4,
    border: '0.5px solid #D9D9D9',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    }
  },
  deleteText: {
    fontSize: 14,
    fontWeight: 400,
    color: `${ptColors.ebonyClay}`,
    lineHeight: '16px',
    marginLeft: 5
  },
  dot: {
    width: 7,
    height: 18,
    backgroundImage:
      'radial-gradient(circle, #939598 1.8px, transparent 1.8px)',
    backgroundSize: '100% 33.33%',
    cursor: 'pointer'
  },
}))

export { useCreateNotificationStyles }
