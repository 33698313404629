import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Dialog } from '@mui/material'

const useAddUserStyles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    padding: '8px',
    right: 24,
    top: 24,
    "&:focus":{
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: '14px'
  },
  dialogBody: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: '16px'
  },
  successBtn: {
    color: '#FFFFFF',
    backgroundColor: '#DD0000',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DD0000',
    height: '44px',
    padding: '10px 19px',
    textTransform: 'none',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    '&:hover': {
      backgroundColor: '#e97272'
    }
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions_form: {
    width: '100%'
  },
  actions_row: {
    width: '100%',
    display: 'flex',
    marginTop: '12px',
    justifyContent: 'space-between'
  },
  actions_row_cell: {
    width: '357px'
  },
  actions_row_divider: {
    width: '100%',
    background: '#D9D9D9',
    height: '1px',
    opacity: 1,
    marginTop: '20px'
  },
  actions_row_buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '10px'
  },
  loaderParent: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255, 0.7)',
    width: '100%',
    height: '100%',
    zIndex: 1
  }
}))

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    marginTop: '100px'
  },
  '& .MuiPaper-root': {
    width: '808px',
    borderRadius: '10px',
    maxWidth: 'unset !important',
    padding: '24px 32px 32px'
  },
  '& .MuiDialogTitle-root': {
    padding: 0
  },
  '& .MuiDialogActions-root': {
    padding: '0',
    justifyContent: 'left'
  },
  '& .MuiDialog-container': {
    height: 'unset !important',
    marginTop: '100px',
    '& :not(:first-of-type)': {
      marginLeft: '0'
    }
  }
}))

export { useAddUserStyles, CustomDialog }
