/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react'
import * as Material from '@mui/material'
import { makeStyles } from '@mui/styles'

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'success'
  | 'danger'
  | 'dark'
  | 'info'
  | 'submit'
  | 'gray'

type ButtonTypes = 'contained' | 'outlined' | 'text'

type ButtonProps = { color?: ColorTypes } & Omit<Material.ButtonProps, 'color'>

export type PTButtonProps = ButtonProps & {
  disabled?: boolean
  text?: string
  onClick?: (event: React.MouseEvent) => void
  className?: string
  color?: ColorTypes
  buttonType?: ButtonTypes
}

const useStyles = makeStyles(() => ({
  customBtn: {
    background: '#fff',
    height: 44,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#666666',
    outline: 'none',
    padding: '10px 20px',
    textTransform: 'none',
    fontSize: '16px',
    '&.outlined': {
      border: '1px solid #CCCCCC',
      borderRadius: 4,
      '&:hover': {
        background: '#f7f7f7'
      },
      '&.primary': {
        color: '#FFFFFF',
        border: '1px solid #0078AB',
        backgroundColor: '#007EB4',
        '&:hover': {
          borderColor: '#0078AB',
          backgroundColor: '#006D9C'
        },
        '&:focus': {
          outline: '5px solid #CCEBF9',
        }
      },
      '&.secondary': {
        color: '#232F3E',
        border: '1px solid #DDDDDD',
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: '#FAFAFA',
          border: '1px solid #D1D1D1',
          color: '#000000'
        },
        '&:focus': {
          outline: '4px solid #CCEBF9',
          color: '#000000'
        }
      },
      '&.danger': {
        color: '#fff',
        borderColor: '#DD0000',
        backgroundColor: '#DD0000',
        borderRadius: 6
      },
      '&.info': {
        color: '#449FE0',
        borderColor: '#449FE0'
      },
      '&.submit': {
        color: '#fff',
        background: '#007EB4',
        border: 'none',
        minWidth: 120
      },
      '&.gray': {
        background: 'gray'
      }
    },
    '&.contained': {
      border: 'none',
      color: 'white',
      backgroundColor: 'gray',
      '&:hover': {},
      '&.secondary': {
        color: '#f7f7f7'
      }
    },
    '&.text': {
      border: 'none',
      '&:hover': {
        background: '#f7f7f7'
      }
    },
    '&.MuiButton-text': {
      color: '#000000'
    },
    '&.Mui-disabled': {
      opacity: 0.4
    }
  }
}))

const Button: React.FC<PTButtonProps> = (props) => {
  const { color, buttonType, text, children, ...rest } = props
  const classes = useStyles({ color })

  return (
    <Material.Button
      {...rest}
      className={`${classes.customBtn} ${color || ''} ${rest.className ?? ''} ${
        buttonType || 'outlined'
      }`}
    >
      {text}
      {children}
    </Material.Button>
  )
}

export default Button
