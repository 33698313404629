/* eslint-disable  */
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
  OverridableTokenClientConfig,
  useGoogleLogin
} from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Header from '../Modecules/Header'
import { ReactComponent as Logo } from '../../images/icon/logo_black.svg'
import { ReactComponent as Google } from '../../images/icon/google.svg'
import { useStyles } from '../../themes'
import { useGoogleLoginMutation } from '../../state/features/googleAuth/googleAuthSlice'
import { useLoginMutation } from '../../state/features/user/userSlice'
import { mapKeysCamelCase } from '../../utils/callApiUtil'
import { authInfoSlice } from '../../state/slice/authInfoSlice'
import { useAppDispatch, AppDispatch, RootState } from '../../state/store'
import { PAGE_PATH_NAME } from '../../constants/constants'
import { useSelector } from 'react-redux'

type GoogleDataProps = {
  sub?: string
  name?: string
  given_name?: string
  family_name?: string
  picture?: string
  email?: string
  email_verified?: boolean
  locale?: string
}

interface newDataProps extends GoogleDataProps {
  access_token?: string
}

type LoginProps = {
  token: string
  login: () => void
  isSuccess: boolean
  data: GoogleDataProps | undefined
}

const Login = ({ token, login, isSuccess, data }: LoginProps) => {
  const classes = useStyles()

  const [responseData, setResponseData] = React.useState<newDataProps>({})

  const [
    ptLoginApi,
    { data: loginData, isSuccess: loginSuccess, error: loginError }
  ] = useLoginMutation()
  const isLogin = useSelector<RootState, boolean>(
    (state) => state?.iotAuthInfo?.isLogin
  )

  const navigate = useNavigate()
  const dispatch: AppDispatch = useAppDispatch()
  const {
    saveName,
    saveGivenName,
    saveFamilyName,
    savePicture,
    saveEmail,
    saveLocale,
    saveAuthId,
    saveAuthName,
    saveAccessToken
  } = authInfoSlice.actions

  React.useEffect(() => {
    if (isSuccess) {
      const newData = { ...data, access_token: token }
      setResponseData(newData)
    }
  }, [isSuccess])

  React.useEffect(() => {
    if (isLogin && responseData && Object.keys(responseData).length) {
      ptLoginApi(responseData)
    }
  }, [responseData, isLogin])

  React.useEffect(() => {
    if (loginSuccess) {
      const convertData = mapKeysCamelCase(loginData?.data)
      dispatch(saveName(convertData?.name))
      dispatch(saveGivenName(convertData?.givenName))
      dispatch(saveFamilyName(convertData?.familyName))
      dispatch(savePicture(convertData?.picture))
      dispatch(saveEmail(convertData?.email))
      dispatch(saveLocale(convertData?.locale))
      dispatch(saveAuthId(convertData?.authId))
      dispatch(saveAuthName(convertData?.authName))
      dispatch(saveAccessToken(convertData?.accessToken))

      navigate(PAGE_PATH_NAME.COMPANY_LIST)
    } else if (loginError) {
      toast.error(loginError?.data?.message)
    }
  }, [loginSuccess, loginError])

  return (
    <>
      <Header />
      <Box className={classes.login}>
        <Box className={classes.login__container}>
          <Box className={classes.login__logo}>
            <Logo />
            <Typography className={classes.login__text}>
              PockeTalk Ventana BackOffice
            </Typography>
          </Box>

          <Button
            className={classes.login__btn}
            onClick={() => {
              setResponseData({})
              login()
            }}
          >
            <Google />
            <Typography className={classes.login__btntext}>
              Sign In with Google
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Login
