/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import {
  DialogTitle,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Divider,
  TextField
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-gray.svg'
import Button from '../../Atoms/Buttons/index'
import CustomInput from '../../Atoms/Inputs'
import inputStyles from '../../Atoms/Inputs/style'
import { useInviteUserMutation } from '../../../state/features/user/userSlice'
import Loader from '../../Atoms/Loader/Loader'
import { useAddUserStyles, CustomDialog } from './style'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const classes = useAddUserStyles()
  const { children, onClose } = props

  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.iconBtn}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export interface dialogProps {
  open: boolean
  handleClose: () => void
  setResponse: React.Dispatch<{ status: boolean; message: string } | null>
}

export interface IAddUserRequest {
  first_name: string
  last_name: string
  mail_address: string
  bo_account_master_id: string
}

const AddUserDialog = ({
  open = true,
  handleClose,
  setResponse
}: dialogProps) => {
  const classes = useAddUserStyles()
  const inputStylesClasses = inputStyles()
  const [inviteUser, result] = useInviteUserMutation()
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
    reset
  } = useForm()

  interface IErrorResponse {
    data: {
      errors: {
        param: string,
        message: string
      }[] | string,
      result_code: string,
      status: number,
      success: boolean,
      message: string
    }
    status: number,
  }

  useEffect(() => {
    const customError = result?.error as IErrorResponse;


    if (customError) {
      if (customError?.data.result_code === "WARN_INPUT_PARAM") {
        if (customError.data.errors && typeof customError.data.errors !== 'string') {
          customError.data.errors.map((element) => {
            setError(element.param, {
              type: 'custom',
              message: element.message
            })

            return null;
          })
        }
        else {
          setResponse({status: false, message: customError?.data.message})
          reset({
            first_name: '',
            last_name: '',
            mail_address: '',
            bo_account_master_id: 'Administrator'
          })
        }
      } else if (customError?.data.result_code !== "WARN_INPUT_PARAM") {
        setResponse({
          status: false,
          message: customError?.data.message
        })
        reset({
          first_name: '',
          last_name: '',
          mail_address: '',
          bo_account_master_id: 'Administrator'
        })
      }
    }
    if (result?.data?.result_code === 'INFO_USER_INVITED') {
      setResponse({
        status: true,
        message: result.data && result.data.message
      });
      reset({
        first_name: '',
        last_name: '',
        mail_address: '',
        bo_account_master_id: 'Administrator'
      })
    }

  }, [result, setError, setResponse, reset])

  const checkEmail = (email: string) => {
    switch (email.split('@')[1]) {
      case 'pocketalk.com':
        return true
      default:
        return false
    }
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if(checkEmail(data.mail_address as string)){
      await inviteUser({
        first_name: data.first_name.replace(/\s+/g, ' ').trim(),
        last_name: data.last_name.replace(/\s+/g, ' ').trim(),
        mail_address: data.mail_address.replace(/\s+/g, ' ').trim(),
        bo_account_master_id: 1, // later it will be replaced with logged in user id at sp 10
        language_master_id: 1,
        is_reinvited: false
      })

    }else{
      setError('mail_address', {
        type: 'custom',
        message: 'This email does not belong to pocketalk'
      })

    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <CustomDialog
        onClose={() => handleClose()}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        {result.isLoading && (
          <Box className={classes.loaderParent}>
            <Loader colorCode='#939598' />
          </Box>
        )}
        <CustomDialogTitle
          id='customized-dialog-title'
          onClose={() => handleClose()}
        >
          <Box className={classes.dialogTitle}>
            <Box className={classes.dialogBody}>
              <Typography
                gutterBottom
                fontSize='18px'
                fontWeight='700'
                margin='0'
                lineHeight='24px'
                color='#232f3e'
              >
                {t('userManagement.addNewUser')}
              </Typography>
              <Typography
                gutterBottom
                fontSize='14px'
                fontWeight='700'
                margin='0'
                lineHeight='20px'
                color='#232f3e'
              >
                {t('userManagement.userAccountInformation')}
              </Typography>
            </Box>
          </Box>
        </CustomDialogTitle>

        <DialogActions className={classes.actions}>
          <form
            className={classes.actions_form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={classes.actions_row}>
              <div className={classes.actions_row_cell}>
                <CustomInput
                  register={register}
                  registerField='first_name'
                  label={`${t('userManagement.firstName')}`}
                  placeholder={`${t('userManagement.typeFirstName')}`}
                  errors={errors}
                  required
                  min={1}
                  max={255}
                />
              </div>
              <div className={classes.actions_row_cell}>
                <CustomInput
                  register={register}
                  registerField='last_name'
                  label={`${t('userManagement.lastName')}`}
                  placeholder={`${t('userManagement.typeLastName')}`}
                  errors={errors}
                  required
                  min={1}
                  max={255}
                />
              </div>
            </div>
            <div className={classes.actions_row}>
              <div className={classes.actions_row_cell}>
                <CustomInput
                  register={register}
                  registerField='mail_address'
                  label={`${t('userManagement.emailAddress')}`}
                  placeholder={`${t('userManagement.typeUserEmail')}`}
                  pattern={{
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    errorMessage: `${t('userManagement.invalidEmail')}`
                  }}
                  errors={errors}
                  min={1}
                  max={255}
                  required
                />
              </div>
              <div className={classes.actions_row_cell}>
                <div className={inputStylesClasses.container}>
                  <div className={inputStylesClasses.container_label}>
                    {t('userManagement.role')}
                  </div>
                  <TextField
                    {...register('bo_account_master_id', {
                      value: 'Administrator'
                    })}
                    disabled
                    className={inputStylesClasses.container_textfield}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#6C7778'
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Divider className={classes.actions_row_divider} />
            <div className={classes.actions_row}>
              <div className={classes.actions_row_buttons}>
                <Button onClick={() => handleClose()} color='secondary'>
                  {t('userManagement.cancel')}
                </Button>
                <Button type='submit' color='primary'>
                  {t('userManagement.addUser')}
                </Button>
              </div>
            </div>
          </form>
        </DialogActions>
      </CustomDialog>
    </div>
  )
}

export default AddUserDialog
