import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthHook from '../../hooks/useAuth';

type unauthorizedRouteProps = {
    element: JSX.Element;
};

const UnauthorizedRoute = ({ element }: unauthorizedRouteProps): JSX.Element => {
    const { isRegisted } = useAuthHook();
    
    return isRegisted() ? <Navigate to="/" /> : element;
};

export default UnauthorizedRoute;