import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WEB_STORAGE } from '../../constants/constants';

/**
 * RMS認証成功後セッションから取得したログイン情報State
 */
type LoginInfoState = {
  // ユーザID
  adminId: string;
  // ユーザの名前
  fname: string;
  // ユーザの苗字
  lname: string;
  // 権限値
  accesscontrolId: string;
  // 権限名
  accesscontrolName: string;
  // 初回：ダミーAPIから返却された利用開始日時/以降：各APIから返却された利用日時
  useDate: string;
};

/**
 * 初期State
 */
const initialState: LoginInfoState = {
  adminId: '',
  fname: '',
  lname: '',
  accesscontrolId: '',
  accesscontrolName: '',
  useDate: '',
};

/**
 * RMS認証成功後セッションから取得したログイン情報 Slice
 */
const loginInfoSlice = createSlice({
  name: WEB_STORAGE.LOGIN_INFO,
  initialState,
  reducers: {
    saveAdminId: (state, action: PayloadAction<string>) => {
      state.adminId = action.payload;
    },
    saveFname: (state, action: PayloadAction<string>) => {
      state.fname = action.payload;
    },
    saveLname: (state, action: PayloadAction<string>) => {
      state.lname = action.payload;
    },
    saveAccesscontrolId: (state, action: PayloadAction<string>) => {
      state.accesscontrolId = action.payload;
    },
    saveAccesscontrolName: (state, action: PayloadAction<string>) => {
      state.accesscontrolName = action.payload;
    },
    saveUseDate: (state, action: PayloadAction<string>) => {
      state.useDate = action.payload;
    },
  },
});

export { loginInfoSlice };
export type { LoginInfoState };
