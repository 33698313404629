import { makeStyles } from '@mui/styles'

const inputStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    fontFamily: 'Noto Sans JP,sans-serif'
  },
  container_label: {
    width: 'fit-content',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center'
  },
  container_asterisk: {
    color: '#E1341E',
    marginLeft: '3px',
    // fontWeight: '700',
    opacity: '80%'
  },
  container_textarea: {
    resize: 'none',
    width: '100%',
    fontFamily: "'Noto Sans JP', sans-serif",
    borderRadius: '6px',
    padding: '9px 16px 27px 16px',
    borderColor: '#D9D9D9',
    outline: 'none',
    height: '160px',
    fontSize: '14px',
    '&:focus': {
      border: '1px solid #9AA5B1'
    },
    '&_error': {},
    '&:disabled': {
      color: '#939598'
    }
  },
  container_textarea_error: {
    resize: 'none',
    borderColor: '#E1341E',
    fontFamily: "'Noto Sans JP', sans-serif",
    width: '100%',
    borderRadius: '6px',
    padding: '9px 16px 27px 16px',
    height: '160px',
    '&:focus': {
      border: '1px solid #E1341E',
      outline: 0
    }
  },
  container_textfield_disabled: {
    width: '100%',
    borderRadius: '4px',
    '& input, textarea': {
      padding: '9px 12px',
      fontSize: '14px',
      '&:disabled': {
        color: 'white'
      }
    },
    '& textarea': {
      paddingTop: '6px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      '& .css-nnbavb': {
        display: 'none'
      }
    }
  },
  labelTooltipIcon: {
    marginLeft: 13,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))

export default inputStyles
