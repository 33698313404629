/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { useEffect, useState, useRef } from 'react'
import {Box, Typography, Button, ListItemButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { format } from 'date-fns'
import { useStyles } from '../../../themes'
import SearchField from '../../Atoms/SearchInput'
// import PageLoadingLayer from '../../Templates/PageLoadingLayer';
import PageTemplate from '../PageTemplate'
// import useDeviceListData from '../../../hooks/useDeviceListData';
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
// import DeviceListTemplate from '../../Templates/DeviceListTemplate';
// import { useDeviceImportListQuery } from '../../../state/features/deviceimport/deviceImportSlice';
import { useLazyDeviceListQuery, useDeleteDeviceMutation } from '../../../state/features/devices/deviceSlice'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
import Table from '../../Templates/Table'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import usePagination from '../../../hooks/usePagination'
import { IColumnProp, IColumnProps } from '../../../types/table'
import CustomTooltip from '../../Atoms/Tooltip'
import { IDeviceResponse } from '../../../types/device'
import { ReactComponent as SimWarning } from '../../../images/icon/sim-warning.svg'
import { ReactComponent as SimExpired } from '../../../images/icon/sim-expired.svg'
import { ReactComponent as MenuIcon } from '../../../images/icon/menu.svg'
import { ReactComponent as Delete } from '../../../images/icon/delete.svg'
import { ReactComponent as DeleteIconModal } from '../../../images/icon/modal-delete.svg'
import StatusButton from '../../Atoms/StatusButton'
import CommonDialog from '../../Atoms/Dialog'
import { STATUS_COLOR_CODE } from '../../../constants/constants'

const DeviceList = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    sortDirection: '',
    colName: '',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })

  // const {
  //   data: deviceList,
  //   isSuccess: deviceListIsSuccess,
  //   refetch: deviceListRefetch,
  //   isFetching: deviceListIsReloading
  // } = useDeviceListQuery(filterQuery)

  const [
    trigger,
    {
      data: deviceList,
      isSuccess: deviceListIsSuccess,
      // refetch: deviceListRefetch,
      isFetching: deviceListIsReloading
    }
  ] = useLazyDeviceListQuery()

  const [
    deleteDevice,
    {
      data: deleteDeviceData,
      isSuccess: deleteDeviceSuccess,
      isError: deleteDeviceError,
      isLoading: deleteDeviceIsLoading,
      error: deleteDeviceErrorData
    }
  ] = useDeleteDeviceMutation()

  const sortFieldsNameReplace = (substr: string) => {
    // corpId|company|imei1|imei2|iccid|productType|simExpiryDt|latestTransDt|deviceStatus
    if (substr === 'corp_id') {
      return 'corpId'
    }
    if (substr === 'company') {
      return 'company'
    }
    if (substr === 'imei1') {
      return 'imei1'
    }
    if (substr === 'imei2') {
      return 'imei2'
    }
    if (substr === 'product_type') {
      return 'productType'
    }
    if (substr === 'iccid') {
      return 'iccid'
    }
    if (substr === 'sim_expiry_dt') {
      return 'simExpiryDt'
    }
    if (substr === 'latest_trans_dt') {
      return 'latestTransDt'
    }
    if (substr === 'status') {
      return 'status'
    }

    return ''
  }

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    deviceList ? deviceList?.data.total_devices : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.searchText, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? '' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? ''
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

// start delete Device
const [openPopperId, setOpenPopperId] = useState<number | null>(null)
const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false)
const buttonRefs = useRef<Array<React.RefObject<HTMLButtonElement>>>([])

const handleDeleteMenuOpen = (
  event: React.MouseEvent<HTMLElement>,
  id: number
) => {
  event.stopPropagation()

  if (openPopperId === id && isPopperOpen) {
    setIsPopperOpen(!openPopperId)
  } else {
    setIsPopperOpen(true)
  }
  setOpenPopperId(id)
}

useEffect(() => {
  const handleClickOutside = (event: MouseEvent) => {
    // Check if the event target is not inside any of the buttonRefs
    if (
      !buttonRefs.current.some((ref) =>
        ref.current?.contains(event.target as Node)
      )
    ) {
      // Clicked outside any of the buttons
      setOpenPopperId(null)
      setIsPopperOpen(false)
    }
  }

  document.addEventListener('click', handleClickOutside)

  return () => {
    document.removeEventListener('click', handleClickOutside)
  }
}, [buttonRefs])
//delete modal
const [deleteDeviceModal, setDeleteDeviceModal] = useState<{
  state: boolean
  device_id: number
}>({ state: false, device_id: 0 })

useEffect(() => {
  if (deleteDeviceSuccess) {
    toast.success('Device Successfully Deleted.')
  }
  if (deleteDeviceError) {
    if (deleteDeviceErrorData.data?.message) {
      toast.error(deleteDeviceErrorData.data.message)
    } else {
      toast.error('Failed to Delete Device.')
    }
  }
}, [deleteDeviceSuccess, deleteDeviceError])

const confirmDelete = () => {
  deleteDevice({ device_id: deleteDeviceModal.device_id })
  setDeleteDeviceModal({ state: false, device_id: 0 })
}
// end delete Device

  const columnProps: IColumnProps = [
    {
      key: 'corp_id',
      name: 'Corporate ID',
      colWidth: 190,
      colMaxWidth: 190,
      isSort: true
    },
    {
      key: 'company',
      name: 'Corporate Name',
      colWidth: 125,
      colMaxWidth: 125,
      isSort: true
    },
    {
      key: 'imei1',
      name: 'IMEI1',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: true
    },
    {
      key: 'imei2',
      name: 'IMEI2',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: true
    },
    {
      key: 'product_type',
      name: 'Device Model',
      colWidth: 125,
      colMaxWidth: 125,
      isSort: true
    },
    {
      key: 'iccid',
      name: 'ICCID',
      colWidth: 160,
      colMaxWidth: 160,
      isSort: true
    },
    {
      key: 'sim_expiry_dt',
      name: 'Sim Exp. Date',
      colWidth: 145,
      colMaxWidth: 145,
      isSort: true
    },
    {
      key: 'latest_trans_dt',
      name: 'Last Used',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: 'status',
      name: 'Status',
      colWidth: 225,
      colMaxWidth: 225,
      isSort: true
    },
    {
      key: 'delete',
      name: '',
      colWidth: 58,
      colMaxWidth: 58,
      isSort: false
    }
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'RED':
        return STATUS_COLOR_CODE.RED
      case 'YELLOW':
        return STATUS_COLOR_CODE.YELLOW
      case 'GRAY':
        return STATUS_COLOR_CODE.GRAY
      case 'GREEN':
        return STATUS_COLOR_CODE.GREEN
      case 'NAVYBLUE':
        return STATUS_COLOR_CODE.NAVYBLUE
      case 'BLUE':
        return STATUS_COLOR_CODE.BLUE
      default:
        return STATUS_COLOR_CODE.NONE
    }
  }

  const cellDataGenerator = (cell: IColumnProp, row: IDeviceResponse,index: number) => {
    // Create a ref for each button
    buttonRefs.current[index] =
      buttonRefs.current[index] || React.createRef<HTMLButtonElement>()

    switch (cell.key) {
      case 'corp_id': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'company': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }

      case 'imei1': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'imei2': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'product_type': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'iccid': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }

      case 'sim_expiry_dt': {
        if (row[cell.key]) {
          const date = new Date()
          const difference = new Date(row[cell.key]).getTime() - date.getTime()
          const differenceInDays = Math.floor(
            difference / (1000 * 60 * 60 * 24)
          )

          if (differenceInDays <= 30 && differenceInDays > 0) {
            return (
              <span
                style={{
                  color: '#F6AB00',
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center'
                }}
              >
                <SimWarning />
                {format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}
              </span>
            )
          }
          if (differenceInDays > 30) {
            return (
              <span style={{ color: '#232F3E' }}>
                {format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}
              </span>
            )
          }
          if (differenceInDays < 0) {
            return (
              <span
                style={{
                  color: '#E1341E',
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center'
                }}
              >
                <SimExpired />
                {format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}
              </span>
            )
          }
        }

        return '-'
      }

      case 'latest_trans_dt': {
        if (row[cell.key]) {
          return format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')
        }

        return '-'
      }

      case 'status': {
        if (row[cell.key]) {
          return (<StatusButton
            bgColor={getStatusColor(row.color_code_name)}
            text={row[cell.key]}
          />)
        }

        return '-'
      }
      
      case 'delete': {
        return (
          <Box className={classes.deleteIconBox}>
            <Button
              id='deleteButton'
              variant='contained'
              className={classes.deleteIconBtn}
              aria-controls={`simple-popper-${row['device_id']}`}
              aria-describedby={`simple-popper-${row['device_id']}`}
              aria-haspopup='true'
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteMenuOpen(event, row['device_id'])
              }}
              ref={buttonRefs.current[index]} // Use the ref corresponding to the current row
            >
              <MenuIcon />
            </Button>
            {openPopperId === row['device_id'] && isPopperOpen && (
              <Box className={classes.deletePopper}>
                <ListItemButton
                  disableRipple
                  onClick={() => {
                    setDeleteDeviceModal({
                      state: true,
                      device_id: row['device_id']
                    })
                  }}
                  className={classes.deletePopperBtn}
                >
                  <Delete />
                  <p style={{ padding: '0', margin: '0' }}>Delete</p>
                </ListItemButton>
              </Box>
            )}
          </Box>
        )
      }


      default:
        break
    }

    return ''
  }

  return (
    <>
      {/* {isLoading && <PageLoadingLayer />} */}
      <PageTemplate>
        <div className={classes.searchBlock}>
          <SearchField
            clickFilterSearch={(val) => {
              setFilterQuery((prevState) => ({
                ...prevState,
                searchText: val
              }))
            }}
            placeholder={`${t('deviceList.searchPlaceholderText')}`}
          />
        </div>
        <Box className={classes.tableCard}>
          <div>
            <Typography className={classes.tableDataCount}>
              {`${t('deviceList.headerTitle')}`} (
              {deviceList?.data?.total_devices ?? 0})
            </Typography>
          </div>
          {deviceList?.data?.total_devices === 0 ? (
            <div className={classes.dataNotFoundBlock}>
              <Corporation />
              <p className={classes.noFountText}>
                {t('deviceList.noDeviceFound')}
              </p>
              <p className={classes.noAvailableText}>
                {t('deviceList.currentlyNoDevices')}
              </p>
            </div>
          ) : (
            <Box className={classes.dataTableWrap}>
              <SimpleBar>
                <Table
                  tableWidth='1700px'
                  gap='33px'
                  dataRows={deviceList ? deviceList.data?.devices : []}
                  clickSortButton={onClickSortButton}
                  sortConfig={sortConfig}
                  isSuccess={deviceListIsSuccess}
                  isReloading={deviceListIsReloading || deleteDeviceIsLoading}
                  skeletonProps={columnProps}
                  columnProps={columnProps}
                  cellDataGenerator={cellDataGenerator}
                />
              </SimpleBar>
              {(deviceListIsReloading || deleteDeviceIsLoading) && deviceListIsSuccess ? (
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    position: 'absolute',
                    top:
                      deviceList?.data?.total_devices === 1
                        ? '10%'
                        : deviceList?.data?.total_devices === 2
                        ? '25%'
                        : '45%',
                    left: '50%',
                    zIndex: '1'
                  }}
                >
                  <Loader colorCode='#939598' />
                </Box>
              ) : null}
              <div className={classes.dataPagination}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(
                    (deviceList ? deviceList.data?.total_devices : 0) /
                      rowsPerPage
                  )}
                  clickPrevPageButton={onClickPrevPage}
                  clickNextPageButton={onClickNextPage}
                  rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                  currentRowNumberPerPage={rowsPerPage}
                  changeRowNumber={onChangeRowNumber}
                  containFirstAndLastButton
                  clickFirstPageButton={onClickFirstPage}
                  clickLastPageButton={onClickLastPage}
                  pageDataIndexFrom={currentPage * rowsPerPage + 1}
                  pageDataIndexTo={
                    (currentPage + 1) * rowsPerPage >
                    (deviceList ? deviceList.data?.total_devices : 0)
                      ? deviceList
                        ? deviceList.data?.total_devices
                        : 0
                      : (currentPage + 1) * rowsPerPage
                  }
                  totalDataRows={
                    deviceList ? deviceList.data?.total_devices : 0
                  }
                />
              </div>
            </Box>
          )}
        </Box>
        <CommonDialog
          open={deleteDeviceModal.state}
          handleClose={() => {
            setDeleteDeviceModal({ state: false, device_id: 0 })
          }}
          handleSubmit={() => {
            confirmDelete()
          }}
          icon={DeleteIconModal}
          title={'Are you sure you want to delete this Device?'}
          description={'This action cannot be undone.'}
          cancelText={'Cancel'}
          successText={'Delete'}
          isModalTop
        />
      </PageTemplate>
    </>
  )
}

export default DeviceList
