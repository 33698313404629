/* eslint-disable import/prefer-default-export */

export const ptColors = {
  niceBlue: '#007EB4',
  ocean: '#008296',
  richElectricBlue: '#0099D9',
  orangePeel: '#FF9900',
  white: '#FFFFFF',
  cadetBlue: '#ABB7C5',
  paleSky: '#6C7778',
  pickledBluewood: '#37475A',
  ebonyClay: '#232F3E',
  alabaster: '#FAFAFA',
  patternsBlue: '#E2F4F7',
  zircon: '#F1F9FE',
  beige: '#F2F6E1',
  harp: '#EAEDED',
  gainsboro: '#DDDDDD',
  santaGrey: '#9AA5B1',
  rossoCorsa: '#DD0000',
  malibu: '#7FCEF0',
  darkGrassGreen: '#49850F',
  flatGreen: '#6D9D3F',
  cantaloupe: '#FFAD33',
  fadedGreen: '#8AB165',
  darkMint: '#4DC176',
  pumpkinOrange: '#FF6712',
  cerulean: '#009DE1',
  dune: '#313133',
  mountainMist: '#939598',
  roseWhite: '#FFF6F6',
  colorRed: '#E1341E'
}
