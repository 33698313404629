/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable prefer-template */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import PageTemplate from '../PageTemplate'
import { useCreateNotificationStyles } from './style'
import { ReactComponent as CheckBoxIcon } from '../../../images/icon/checkbox.svg'
import { ReactComponent as CheckedCheckBox } from '../../../images/icon/checked_checkbox.svg'
import CustomSelect from '../../Atoms/CustomSelect'
import { ReactComponent as PreviewIcon } from '../../../images/icon/Preview.svg'
import { ReactComponent as ExclamatoryIcon } from '../../../images/icon/exclamatory.svg'
import CustomInput from '../../Atoms/Inputs'
import Datepicker from '../../Atoms/Datepicker'
import { DropdownErrorType, OptionsType } from '../../../types/common'
import TextArea from '../../Atoms/TextArea'
import { CustomEmailPreviewDialog } from '../../Modecules/EmailPreview/style'
import BellPreviewDialog from '../../Modecules/BellPreview'
import EmailPreviewDialog from '../../Modecules/EmailPreview'
import { useCreateNotificationMutation } from '../../../state/features/notifications/notificationSlice'
import Button from '../../Atoms/Buttons'

export type NotificationCreateType = {
  notification_type: string
  notification_method: string
  recipient: string
  schedule_status: string
  schedule_date?: string
  publication_date?: string
  mail_subject?: string
  mail_template?: string
  email_body?: string
  bell_message_title?: string
  bell_message_detail?: string
}

interface INotificationCreateForm {
  mailPreview: boolean
  bellPreview: boolean
  schedule: {
    state: OptionsType
    scheduleDate: string
    publicationDate: string
  }
  notificationType: OptionsType
  mailTemplate: OptionsType
  destinationType: {
    mail: boolean
    bell: boolean
  }
  recipient: OptionsType
}

export const initialNotificationCreateData: NotificationCreateType = {
  notification_type: '',
  notification_method: 'bell',
  recipient: '',
  schedule_status: 'yes',
  mail_subject: '',
  mail_template: '',
  email_body: '',
  bell_message_title: '',
  bell_message_detail: ''
}

const options = {
  scheduleOptions: [
    {
      label: 'Yes',
      value: 'yes'
    },
    {
      label: 'No',
      value: 'no'
    }
  ],
  notificationTypeOptions: [
    {
      label: 'Announcement',
      value: 'Announcement'
    }
  ],
  mailTemplateOptions: [
    {
      label: 'Notification Mail Template',
      value: 'notification_mail_template'
    }
  ],
  recipientOptions: [
    {
      label: 'All Users',
      value: 'ALL'
    },
    {
      label: 'Admin',
      value: 'ADMIN'
    }
  ]
}

const NotificationCreate = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [selectedOptions, setSelectedOptions] =
    useState<INotificationCreateForm>({
      mailPreview: false,
      bellPreview: false,
      schedule: {
        state: {
          label: 'No',
          value: 'no'
        },
        scheduleDate: '',
        publicationDate: ''
      },
      notificationType: {
        label: 'Announcement',
        value: 'Announcement'
      },
      mailTemplate: {
        label: 'Notification Mail Template',
        value: 'notification_mail_template'
      },
      destinationType: {
        mail: false,
        bell: false
      },
      recipient: {
        label: 'All Users',
        value: 'ALL'
      }
    })

  const classes = useCreateNotificationStyles()
  const [minVal, setMinVal] = useState<Date>(new Date())

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    getValues,
    setValue
  } = useForm()

  const getNotificationType = (): string => {
    if (
      selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      return 'Both'
    }
    if (selectedOptions.destinationType.mail) {
      return 'Mail'
    }
    if (selectedOptions.destinationType.bell) {
      return 'Bell'
    }
    return ''
  }

  const getFormattedDate = (dateObj: Date) => {
    const years = dateObj.getFullYear()
    const months =
      dateObj.getMonth() + 1 < 10
        ? '0' + String(dateObj.getMonth() + 1)
        : String(dateObj.getMonth() + 1)
    const date =
      dateObj.getDate() < 10
        ? '0' + String(dateObj.getDate())
        : dateObj.getDate()
    const hours =
      dateObj.getHours() < 10
        ? '0' + String(dateObj.getHours())
        : dateObj.getHours()
    const minutes =
      dateObj.getMinutes() < 10
        ? '0' + String(dateObj.getMinutes())
        : dateObj.getMinutes()
    const seconds =
      dateObj.getSeconds() < 10
        ? '0' + String(dateObj.getSeconds())
        : dateObj.getSeconds()
    return `${years}-${months}-${date} ${hours}:${minutes}:${seconds}.000`
  }

  const [createNotification, { data, isError, error, isLoading, isSuccess }] =
    useCreateNotificationMutation()

  const [isMailPreviewed, setIsMailPreviewed] = useState<boolean>(false)
  const [isBellPreviewed, setIsBellPreviewed] = useState<boolean>(false)
  // const [isDraft, setIsDraft] = useState<boolean>(false)
  const [formValid, setFormValid] = useState<boolean>(false)

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message)
      navigate('/notifications')
    }
    if (isError) {
      toast.error(error?.data.message)
    }
  }, [isSuccess, isError, data, error, navigate])

  const [scheduleDate, setScheduleDate] = useState<Date | null>(null)
  const [publicationDate, setPublicationDate] = useState<Date | null>(null)

  const watching = watch([
    'mail_subject',
    'mail_body',
    'bell_message_title',
    'bell_message_detail'
  ])
  useEffect(() => {
    if (
      selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      if (
        getValues('mail_subject') &&
        getValues('mail_body') &&
        getValues('bell_message_title') &&
        getValues('bell_message_detail') &&
        isMailPreviewed &&
        isBellPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else if (
      !selectedOptions.destinationType.mail &&
      selectedOptions.destinationType.bell
    ) {
      if (
        getValues('bell_message_title') &&
        getValues('bell_message_detail') &&
        isBellPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else if (
      selectedOptions.destinationType.mail &&
      !selectedOptions.destinationType.bell
    ) {
      if (
        getValues('mail_subject') &&
        getValues('mail_body') &&
        isMailPreviewed
      ) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
    } else {
      setFormValid(false)
    }
  }, [getValues, selectedOptions, watching, isBellPreviewed, isMailPreviewed])

  const onSubmit: any = async (data: any, flag: string) => {
    const dataBody: any = {}
    if (
      !selectedOptions.destinationType.bell &&
      !selectedOptions.destinationType.mail
    ) {
      toast.error('Notification method cannot be empty')
      return
    }
    if (
      (selectedOptions.schedule.state.value === 'yes' && !scheduleDate) ||
      (selectedOptions.schedule.state.value === 'yes' && !publicationDate)
    ) {
      toast.error('Please select a time range for schedule')
      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate &&
      scheduleDate.getTime() > publicationDate.getTime()
    ) {
      setPublicationDate(null)
      toast.error('Schedule date and time cannot be after publication date and time')
      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate &&
      scheduleDate.getTime() === publicationDate.getTime()
    ) {
      setPublicationDate(null)
      toast.error('Schedule date and time and publication date and time cannot be the same')
      return
    }
    if (
      (selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      scheduleDate.getTime() < new Date().getTime()) && 
      (selectedOptions.schedule.state.value === 'yes' &&
      publicationDate &&
      publicationDate.getTime() < new Date().getTime())
   
    ) {
      setScheduleDate(null)
      setPublicationDate(null)
      toast.error('Date and time selected cannot be before current date and time')
      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      scheduleDate.getTime() < new Date().getTime()
    ) {
      setScheduleDate(null)
      toast.error('Schedule date cannot be before the current date and time')
      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      publicationDate &&
      publicationDate.getTime() < new Date().getTime()
    ) {
      setPublicationDate(null)
      toast.error('Publication date cannot be before the current date and time')
      return
    }
    if (
      selectedOptions.schedule.state.value === 'yes' &&
      scheduleDate &&
      publicationDate
    ) {
      dataBody.scheduler_start_date = getFormattedDate(
        new Date(
          scheduleDate.getTime() +
            new Date(scheduleDate).getTimezoneOffset() * 60000
        )
      )
      dataBody.scheduler_end_date = getFormattedDate(
        new Date(
          publicationDate.getTime() +
            new Date(publicationDate).getTimezoneOffset() * 60000
        )
      )
    }

    dataBody.notification_type = selectedOptions.notificationType.value
    dataBody.user_type = selectedOptions.recipient.value
    dataBody.destination_type = getNotificationType()
    dataBody.is_scheduled =
      selectedOptions.schedule.state.value === 'yes' ? true : false

    dataBody.notification_status = flag

    if (selectedOptions.destinationType.mail) {
      dataBody.mail_subject = getValues('mail_subject').trim()
      dataBody.mail_body = getValues('mail_body').trim()
    }

    if (selectedOptions.destinationType.bell) {
      dataBody.bell_message_title = getValues('bell_message_title').trim()
      dataBody.bell_message_detail = getValues('bell_message_detail').trim()
    }

    void createNotification(dataBody)
  }

  return (
    <PageTemplate>
      <Box className={classes.actionBlock}>
        <Button
          className={classes.draftBtn}
          onClick={handleSubmit((formData) => onSubmit(formData, 'DRAFTED'))}
        >
          {t('notificationDetails.saveAsDraft')}
        </Button>
        <Tooltip
          arrow
          placement='top'
          title={<p>{t('notificationDetails.requirePreview')}</p>}
          sx={{ marginTop: 0 }}
          componentsProps={{
            tooltip: {
              sx: {
                display: formValid ? 'none' : '',
                width: '160px',
                padding: '6px 11px',
                textAlign: 'center',
                fontWeight: 400,
                fontSize: '14px',
                backgroundColor: '#37475A',
                lineHeight: '17px'
              }
            }
          }}
        >
          <span>
            <Button
              color='primary'
              disabled={!formValid}
              className={classes.submitBtn}
              onClick={handleSubmit((formData) =>
                onSubmit(
                  formData,
                  'SUBMITTED'
                  // selectedOptions.schedule.state.value === 'no'
                  //   ? 'SUBMITTED'
                  //   : 'SCHEDULED'
                )
              )}
            >
              {t('notificationDetails.submit')}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <form>
        <Box className={classes.createNotificationContainer}>
          <Box className={classes.cardContainer}>
            <Box>
              <Typography className={classes.headerText}>
                Create New Notification
              </Typography>
              <Box>
                <Box className={classes.formBlock}>
                  <Typography className={classes.textTitle}>
                    Basic Information
                  </Typography>
                  <Box className={classes.formRow}>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        <CustomSelect
                          disabled={options.notificationTypeOptions.length <= 1}
                          selectedOption={selectedOptions.notificationType}
                          optionChangeHandler={(option: any) => {
                            register('notification_type', {
                              value: option.value
                            })
                            setSelectedOptions((prevState: any) => {
                              return { ...prevState, notificationType: option }
                            })
                          }}
                          options={options.notificationTypeOptions}
                          labelText='Notification type'
                          required
                          defaultValue={options.notificationTypeOptions[0]}
                          name='notification_type'
                        />
                      </Box>
                    </Box>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        <CustomSelect
                          disabled={options.recipientOptions.length <= 1}
                          selectedOption={selectedOptions.recipient}
                          optionChangeHandler={(option: any) => {
                            register('user_type', { value: option.value })
                            setSelectedOptions((prevState: any) => {
                              return { ...prevState, recipient: option }
                            })
                          }}
                          options={options.recipientOptions}
                          labelText='Recipient'
                          required
                          defaultValue={options.recipientOptions[0]}
                          name='recipient'
                        />
                      </Box>
                    </Box>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        <Typography className={classes.formLabel}>
                          Notification Method{' '}
                          <span className={classes.required}>*</span>
                        </Typography>
                        <FormGroup row className={classes.formGroup}>
                          <FormControlLabel
                            className={classes.checkBoxLabel}
                            control={
                              <Checkbox
                                className={classes.checkBoxInput}
                                checked={selectedOptions.destinationType.mail}
                                onChange={(e) => {
                                  setSelectedOptions((prevState: any) => ({
                                    ...prevState,
                                    destinationType: {
                                      ...prevState.destinationType,
                                      mail: !selectedOptions.destinationType
                                        .mail
                                    }
                                  }))
                                  if (e.target.checked) {
                                    setValue('mail_subject', '')
                                    setValue('mail_body', '')
                                  }
                                  // if (!selectedOptions.mailPreview) {
                                  //   setSelectedOptions((prevState) => ({
                                  //     ...prevState,
                                  //     mailSubject: '',
                                  //     mailBody: ''
                                  //   }))
                                  // }
                                }}
                                name='notification_method'
                                value='mail'
                                icon={
                                  <CheckBoxIcon
                                    style={{
                                      marginTop: '1px',
                                      marginRight: '-2px'
                                    }}
                                  />
                                }
                                checkedIcon={<CheckedCheckBox />}
                              />
                            }
                            label='Mail'
                          />
                          <FormControlLabel
                            className={classes.checkBoxLabel}
                            control={
                              <Checkbox
                                className={classes.checkBoxInput}
                                checked={selectedOptions.destinationType.bell}
                                onChange={(e) => {
                                  setSelectedOptions((prevState: any) => ({
                                    ...prevState,
                                    destinationType: {
                                      ...prevState.destinationType,
                                      bell: !selectedOptions.destinationType
                                        .bell
                                    }
                                  }))
                                  if (!e.target.checked) {
                                    setValue('bell_message_title', '')
                                    setValue('bell_message_detail', '')
                                    setScheduleDate(null)
                                    setPublicationDate(null)
                                    setSelectedOptions((prevState) => {
                                      return {
                                        ...prevState,
                                        schedule: {
                                          ...prevState.schedule,
                                          state: { value: 'no', label: 'No' }
                                        }
                                      }
                                    })
                                  }
                                }}
                                name='notification_method'
                                value='bell'
                                icon={
                                  <CheckBoxIcon
                                    style={{
                                      marginTop: '1px',
                                      marginRight: '-2px'
                                    }}
                                  />
                                }
                                checkedIcon={<CheckedCheckBox />}
                              />
                            }
                            label='Bell'
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.formBlock}>
                  <Typography className={classes.textTitle}>
                    Schedule Information
                  </Typography>
                  <Box className={classes.formRow}>
                    <Box className={classes.formCol}>
                      <Box className={classes.formInput}>
                        <CustomSelect
                          disabled={!selectedOptions.destinationType.bell}
                          selectedOption={selectedOptions.schedule.state}
                          optionChangeHandler={(option: any) => {
                            if (selectedOptions.schedule.state) {
                              register('scheduler_start_date', {
                                value: new Date()
                              })
                              register('scheduler_end_date', {
                                value: new Date()
                              })
                            } else {
                              register('scheduler_start_date', {
                                value: null
                              })
                              register('scheduler_end_date', { value: null })
                            }
                            setSelectedOptions((prevState: any) => {
                              return {
                                ...prevState,
                                schedule: {
                                  ...prevState.schedule,
                                  state: option
                                }
                              }
                            })
                          }}
                          options={options.scheduleOptions}
                          labelText='Schedule'
                          required
                          defaultValue={options.scheduleOptions[0]}
                          name='schedule_status'
                        />
                      </Box>
                    </Box>
                    {selectedOptions.schedule.state.value === 'yes' ? (
                      <Box className={classes.formCol}>
                        <Box className={classes.formInput}>
                          <Typography className={classes.formLabel}>
                            Schedule Date{' '}
                            <span className={classes.required}>*</span>
                          </Typography>
                          <Datepicker
                            onDateChange={(date) => {
                              setScheduleDate(date)
                              if (publicationDate && date > publicationDate) {
                                setPublicationDate(null)
                              }
                            }}
                            value={scheduleDate}
                            min={new Date()}
                          />
                        </Box>
                      </Box>
                    ) : null}
                    {selectedOptions.schedule.state.value === 'yes' ? (
                      <Box className={classes.formCol}>
                        <Typography className={classes.formLabel}>
                          Publication Deadline{' '}
                          <span className={classes.required}>*</span>
                        </Typography>
                        <Datepicker
                          onDateChange={(date) => {
                            setPublicationDate(date)
                          }}
                          min={new Date()}
                          value={publicationDate}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              {selectedOptions.destinationType.mail ? (
                <Box className={classes.mailBlock} id='mail'>
                  <Box className={classes.headerBlock}>
                    <Typography className={classes.headerTitle}>
                      Mail Information
                    </Typography>
                    <button
                      disabled={
                        !!(
                          getValues('mail_subject') === '' ||
                          getValues('mail_body') === ''
                        )
                      }
                      type='button'
                      className={classes.previewBtn}
                      onClick={() => {
                        setIsMailPreviewed(true)
                        setSelectedOptions((prevState: any) => ({
                          ...prevState,
                          mailPreview: true
                        }))
                      }}
                    >
                      <span className={classes.previewText}>Preview</span>
                      <PreviewIcon />
                    </button>
                  </Box>

                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomInput
                      register={register}
                      registerField='mail_subject'
                      label='Mail Subject'
                      placeholder='Subject'
                      errors={errors}
                      required
                      min={1}
                      max={256}
                    />
                  </Box>
                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomSelect
                      disabled={options.mailTemplateOptions.length <= 1}
                      selectedOption={selectedOptions.mailTemplate}
                      optionChangeHandler={(option) => {
                        setSelectedOptions((prevState: any) => {
                          return { ...prevState, mailTemplate: option }
                        })
                      }}
                      options={options.mailTemplateOptions}
                      labelText='Mail Template'
                      required
                      defaultValue={options.mailTemplateOptions[0]}
                      name='recipient'
                    />
                  </Box>
                  <Box>
                    <TextArea
                      register={register}
                      registerField='mail_body'
                      label='Email Body'
                      placeholder='Type the message here...'
                      errors={errors}
                      required
                      min={1}
                      max={2000}
                      labelIcon={<ExclamatoryIcon />}
                      labelIconTooltipText={`${t(
                        'notificationDetails.importantMessage'
                      )}`}
                    />
                  </Box>
                </Box>
              ) : null}
              {selectedOptions.destinationType.bell ? (
                <Box className={classes.bellBlock} id='bell'>
                  <Box className={classes.headerBlock}>
                    <Typography className={classes.headerTitle}>
                      Bell Information
                    </Typography>
                    <button
                      disabled={
                        !(
                          getValues('bell_message_title') &&
                          getValues('bell_message_detail')
                        )
                      }
                      type='button'
                      className={classes.previewBtn}
                      onClick={() => {
                        setIsBellPreviewed(true)
                        setSelectedOptions((prevState: any) => ({
                          ...prevState,
                          bellPreview: true
                        }))
                      }}
                    >
                      <span className={classes.previewText}>Preview</span>
                      <PreviewIcon />
                    </button>
                  </Box>
                  <Box className={`${classes.formInput} ${classes.mb13}`}>
                    <CustomInput
                      register={register}
                      registerField='bell_message_title'
                      label='Bell Title'
                      placeholder='Subject'
                      errors={errors}
                      required
                      min={1}
                      max={256}
                    />
                  </Box>
                  <Box>
                    <TextArea
                      register={register}
                      registerField='bell_message_detail'
                      label='Notification Detail'
                      placeholder='Type the message here...'
                      errors={errors}
                      required
                      min={1}
                      max={2000}
                      labelIcon={<ExclamatoryIcon />}
                      labelIconTooltipText={`${t(
                        'notificationDetails.importantMessage'
                      )}`}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </form>
      {selectedOptions.mailPreview ? (
        <EmailPreviewDialog
          date={`${new Date().getFullYear()}/${String(
            new Date().getMonth() + 1
          )}/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`}
          title={getValues('mail_subject')}
          body={getValues('mail_body')}
          handleClose={() => {
            setSelectedOptions((prevState: any) => {
              return { ...prevState, mailPreview: false }
            })
          }}
          open={selectedOptions.mailPreview}
        />
      ) : null}
      {selectedOptions.bellPreview ? (
        <BellPreviewDialog
          date={`${new Date().getFullYear()}/${String(
            new Date().getMonth() + 1
          )}/${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`}
          title={getValues('bell_message_title')}
          body={getValues('bell_message_detail')}
          handleClose={() => {
            setSelectedOptions((prevState: any) => {
              return { ...prevState, bellPreview: false }
            })
          }}
          open={selectedOptions.bellPreview}
        />
      ) : null}
    </PageTemplate>
  )
}

export default NotificationCreate
