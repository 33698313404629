/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import * as Material from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { ptColors } from '../../../themes/settings/colors';

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'success'
  | 'danger'
  | 'dark'
  | 'info'
  | 'submit'
  | 'gray';

type ButtonProps = { color?: ColorTypes } & Omit<Material.ButtonProps, 'color'>;

export type PTButtonProps = ButtonProps & {
  text?: string | number;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
  color?: ColorTypes;
  bgColor?: string;
  borderColor?: string | null;
};

const StatusButton: React.FC<PTButtonProps> = (props) => {
  const { color, text, bgColor, borderColor, children, ...rest } = props;

  const useStyles = makeStyles(() => ({
    customStatusBtn: {
      backgroundColor: `${bgColor || '#fff'}`,
      border: `1px solid ${borderColor || 'none'}`,
      color: `${borderColor || '#fff'}`,
      borderRadius: 16,
      height: 25,
      fontSize: 12,
      alignItems: 'center',
      display: "flex",
      justifyContent: "center",
      width: "fit-content",
      padding: '0px 10px',
      fontWeight: 400,
      fontFamily: "'Noto Sans JP', sans-serif",
      textTransform: 'capitalize'
    },
  }));

  const classes = useStyles({ color });

  return (
    <div
      // {...rest}
      className={`${classes.customStatusBtn} ${color || ''} ${
        rest.className ?? ''
      } `}
    >
      {text}
      {children}
    </div>
  );
};

export default StatusButton;
