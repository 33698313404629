/**
 * 各画面のパス名
 */
export const PAGE_PATH_NAME = {
  // ［メニュー］画面
  MENU: '/',
  // ［アカウント作成メール送信］画面
  MAIL_SEND: '/mailsend',
  // ［アカウント作成メール送信完了］画面
  SENT: '/sent',
  // ［端末一括登録］画面
  DEVICE_REGIST: '/deviceregist',
  // ［端末一括登録完了］画面
  DEVICE_REGISTED: '/deviceregisted',
  // ［エラー］画面
  ERROR: '/error',
  // [法人一覧]画面
  COMPANY_LIST: '/corporations',

  // [法人一覧]画面
  COMPANY_DETAILS: '/corporations/details',
  // [法人詳細]画面
  COMPANY: '/corporations/company',
  // [辞書削除完了]画面
  DICTIONARY_DELETE_COMPLETE: '/dictionary/deletecomplete',
  // [辞書ファイルアップロード完了画面]
  DICTIONARY_UPLOAD_COMPLETE: '/dictionary/uploadcomplete',
  DEVICES: '/devices',
  DEVICE_IMPORT: '/device-import',
  USER_MANAGEMENT: '/user-management',
  NOTIFICATION_MANAGEMENT: '/notifications',
  NOTIFICATION_CREATE: '/notifications/create-notification',
  LOGIN: '/login',
  NOTIFICATION_DETAILS: '/notifications/notification-details',
}

export const PAGE_PATH_NAME_TITLE = {
  // ［メニュー］画面
  devices: 'Devices',
  // ［アカウント作成メール送信］画面
  mailsend: 'User Management',
  // ［アカウント作成メール送信完了］画面
  sent: 'Sent',
  // ［端末一括登録］画面
  deviceregist: 'Device Import',
  // ［端末一括登録完了］画面
  deviceregisted: 'Device Registered',
  // ［エラー］画面
  error: 'Error',
  // [法人一覧]画面
  corporations: 'Corporations',
  // [法人詳細]画面
  corporatedetail: 'Corporation Details',
  // [辞書削除完了]画面
  deletecomplete: 'Delete Completed',
  // [辞書ファイルアップロード完了画面]
  uploadcomplete: 'Upload Completed',
  company: 'Corporate Detail',
  'device-import': 'Device Import',
  'user-management': 'User Management',
  'notifications': 'Notifications',
  'notifications/notification-update': 'Notification Update'
}

/**
 * 各画面名
 */
export const PAGE_VIEW_NAME = {
  // ［メニュー］画面
  MENU: 'メニュー画面',
  // ［アカウント作成メール送信］画面
  MAIL_SEND: 'アカウント作成メール送信画面',
  // ［アカウント作成メール送信完了］画面
  SENT: 'アカウント作成メール送信完了画面',
  // ［端末一括登録］画面
  DEVICE_REGIST: '端末一括登録画面',
  // ［端末一括登録完了］画面
  DEVICE_REGISTED: '端末一括登録完了画面',
  // ［エラー］画面
  ERROR: 'エラー画面',
  // [法人一覧]画面
  COMPANY_LIST: '法人一覧画面',
  // [法人詳細]画面
  COMPANY: '法人詳細画面',
  // [辞書削除完了]画面
  DICTIONARY_DELETE_COMPLETE: '辞書削除完了画面',
  // [辞書ファイルアップロード完了]画面
  DICTIONARY_UPLOAD_COMPLETE: '辞書ファイルアップロード完了画面'
}

/**
 * 各画面情報MAP
 */
export const PAGE_INFO_MAP = [
  { path: PAGE_PATH_NAME.MENU, name: PAGE_VIEW_NAME.MENU },
  { path: PAGE_PATH_NAME.MAIL_SEND, name: PAGE_VIEW_NAME.MAIL_SEND },
  { path: PAGE_PATH_NAME.SENT, name: PAGE_VIEW_NAME.SENT },
  {
    path: PAGE_PATH_NAME.DEVICE_REGIST,
    name: PAGE_VIEW_NAME.DEVICE_REGIST
  },
  {
    path: PAGE_PATH_NAME.DEVICE_REGISTED,
    name: PAGE_VIEW_NAME.DEVICE_REGISTED
  },
  {
    path: PAGE_PATH_NAME.ERROR,
    name: PAGE_VIEW_NAME.ERROR
  },
  {
    path: PAGE_PATH_NAME.COMPANY_LIST,
    name: PAGE_VIEW_NAME.COMPANY_LIST
  },
  {
    path: PAGE_PATH_NAME.COMPANY,
    name: PAGE_VIEW_NAME.COMPANY
  },
  {
    path: PAGE_PATH_NAME.DICTIONARY_DELETE_COMPLETE,
    name: PAGE_VIEW_NAME.DICTIONARY_DELETE_COMPLETE
  },
  {
    path: PAGE_PATH_NAME.DICTIONARY_UPLOAD_COMPLETE,
    name: PAGE_VIEW_NAME.DICTIONARY_UPLOAD_COMPLETE
  }
]

/**
 * 各メニュー名
 */
export const MENU_NAME = {
  // ［メニュー］画面
  MENU: 'メニュー',
  // ［アカウント作成メール送信］画面
  MAIL_SEND: 'アカウント作成メール送信',
  // ［端末一括登録］画面
  DEVICE_REGIST: '端末一括登録',
  // [法人一覧]画面
  COMPANY_LIST: '法人一覧'
}

export const TABLE_ROW_NUMBER_PER_PAGE = [30, 50, 100, 200]

/**
 * 各APIのURL
 */
export const API_URL = {
  // (バックオフィス)統合認証API
  INTEGRATED_API: '/iotconsoleapi-bo/api/pub/integrated',
  // 招待メール送信API
  INVITATION_SEND_MAIL_API: '/iotconsoleapi-bo/api/auth/invitationsendmail',
  // 端末登録API
  DEVICE_REGISTER_API: '/iotconsoleapi-bo/api/auth/deviceregister',
  // 法人一覧取得API
  CORPORATION_LIST_API: '/iotconsoleapi-bo/api/auth/corporationlist',
  // 利用言語一覧API
  LANGUAGE_LIST_API: '/iotconsoleapi-bo/api/auth/languagelist',
  // アカウント情報存在確認API(管理コンソール)
  ACCOUNT_CHECK_CONSOLE_API: '/iotconsoleapi-bo/api/auth/accountcheck',
  // 法人情報取得API
  CORPORATION_INFO_API: '/iotconsoleapi-bo/api/auth/corporationinfo',
  // 辞書登録済み確認API
  GLOSSARY_EXISTS_API: '/iotconsoleapi-bo/api/auth/glossaryexists',
  // 辞書削除API
  GLOSSARY_DELETE_API: '/iotconsoleapi-bo/api/auth/glossarydelete',
  // 辞書登録API
  GLOSSARY_REGISTER_API: '/iotconsoleapi-bo/api/auth/glossaryregister',
  // Gūguru oauth api
  GOOGLE_AUTH_API: 'https://www.googleapis.com/oauth2/v3'
}

/**
 * WEBストレージ
 */
export const WEB_STORAGE = {
  // RMS認証成功後セッションから取得したログイン情報
  LOGIN_INFO: 'iotLoginInfo',
  AUTH_INFO: 'iotAuthInfo'
} as const

/**
 * URLパラメータに付与されるkey名
 */
export const URL_PARAMETER_KEY = {
  // ワンタイムキー
  ONE_TIME_KEY: 'key'
} as const

/**
 * 権限ID
 */
export const ACCESSCONTROL_ID = {
  // 開発者権限
  DEV: 'DEV'
}

/**
 * 権限名
 */
export const ACCESSCONTROL_NAME = {
  // 開発者権限
  DEV: '開発'
}

/**
 * 権限情報MAP
 */
export const ACCESSCONTROL_MAP = [
  { id: ACCESSCONTROL_ID.DEV, name: ACCESSCONTROL_NAME.DEV }
] as const

/**
 * APIリクエスト設定
 */
export const API_REQUEST_CONFIG = {
  // APIリクエスト時のタイムアウト時間(ミリ秒)
  TIMEOUT: 30000
} as const

export const GOOGLE_CLIENT_ID =
  '331230062057-fl2et96bhepk9g8npjpf5gojg4krqcrh.apps.googleusercontent.com'

/**
 * 日付フォーマット(yyyy/MM形式)(言語関係なく統一)
 */
export const DATE_FORMAT = 'yyyy/MM' as const

/**
 * 日付フォーマット(yyyy/MM/dd形式)(言語関係なく統一)
 */
export const DATE_FORMAT_YYYYMMDD = 'yyyy/MM/dd' as const

export const STATUS_COLOR_CODE = {
  YELLOW: '#F6AB00',
  RED: '#E1341E',
  GRAY: '#939598',
  GREEN: '#00A73B',
  NAVYBLUE: '#009de1',
  BLUE: '#007EB4',
  NONE: ''
}