/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { format } from 'date-fns'
import { useStyles } from '../../../themes'
import SearchField from '../../Atoms/SearchInput'
import PageTemplate from '../PageTemplate'
import { PAGE_PATH_NAME, PAGE_PATH_NAME_TITLE, TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
import Table from '../../Templates/Table'
import Loader from '../../Atoms/Loader/Loader'
import Pagination from '../../Modecules/Pagination/Pagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import usePagination from '../../../hooks/usePagination'
import { IColumnProp, IColumnProps } from '../../../types/table'
import StatusButton from '../../Atoms/StatusButton'
import { useLazyNotificationListQuery } from '../../../state/features/notifications/notificationSlice'
import Button from '../../Atoms/Buttons'
import { ReactComponent as AddIcon } from '../../../images/icon/addIcon.svg'
import MultiLineTooltip from '../../Atoms/Tooltip/MultiLineTooltip'
import { convertBlockUnderscoreTextIntoCamelCaseWithSpace } from '../../../utils/utility'

export type notificationDetailsStateProps = {
  notificationId: number
}

const NotificationHistoryList = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    sortDirection: '',
    colName: '',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0
  })
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })

  const [
    trigger,
    {
      data: notificationHistoryList,
      isSuccess: notificationHistoryListIsSuccess,
      isFetching: notificationHistoryListIsFetching
    }
  ] = useLazyNotificationListQuery()

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [trigger, filterQuery])

  const sortFieldsNameReplace = (substr: string) => {
    if (substr === 'bell_message_title') {
      return 'bellMessageTitle'
    }
    if (substr === 'mail_subject') {
      return 'mailSubject'
    }
    if (substr === 'destination_info') {
      return 'destinationInfo'
    }
    if (substr === 'scheduler_start_date') {
      return 'schedulerStartDate'
    }
    if (substr === 'notified_dt') {
      return 'notifiedDt'
    }
    if (substr === 'scheduler_end_date') {
      return 'schedulerEndDate'
    }
    if (substr === 'status') {
      return 'status'
    }

    return ''
  }

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    notificationHistoryList
      ? notificationHistoryList?.data.total_notifications
      : 0
  )

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.searchText, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? '' : sortConfigUpdate.direction,
      colName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? ''
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  const columnProps: IColumnProps = [
    {
      key: 'bell_message_title',
      name: 'Bell Title',
      colWidth: 285,
      colMaxWidth: 285,
      isSort: true
    },
    {
      key: 'mail_subject',
      name: 'Mail Title',
      colWidth: 265,
      colMaxWidth: 265,
      isSort: true
    },
    {
      key: 'destination_info',
      name: 'Notification Method',
      colWidth: 150,
      colMaxWidth: 150,
      isSort: true
    },
    {
      key: 'scheduler_start_date',
      name: 'Schedule Date',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: 'notified_dt',
      name: 'Sent Date',
      colWidth: 140,
      colMaxWidth: 140,
      isSort: true
    },
    {
      key: 'scheduler_end_date',
      name: 'Publication Period',
      colWidth: 150,
      colMaxWidth: 150,
      isSort: true
    },
    {
      key: 'status',
      name: 'Status',
      colWidth: 100,
      colMaxWidth: 100,
      isSort: true
    }
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'ABANDONED':
        return '#FF6712'
      case 'POSTED':
        return '#8AB165'
      case 'SUBMITTED':
          return '#8AB165'
      case 'SCHEDULED':
        return '#9AA5B1'
      case 'DRAFTED':
        return '#F6AB00'
      default:
        return ''
    }
  }

  const cellDataGenerator = (cell: IColumnProp, row: any) => {
    switch (cell.key) {
      case 'bell_message_title': {
        if (row[cell.key]) {
          return <MultiLineTooltip toolTipTitle={row[cell.key]} />
        }

        return '-'
      }
      case 'mail_subject': {
        if (row[cell.key]) {
          return <MultiLineTooltip toolTipTitle={row[cell.key]} />
        }

        return '-'
      }
      case 'destination_info': {
        if (row[cell.key]) {
          return <div>{row[cell.key]}</div>
        }

        return '-'
      }
      case 'scheduler_start_date': {
        if (row[cell.key]) {
          return (
            <div>{format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}</div>
          )
        }

        return '-'
      }
      case 'notified_dt': {
        if (row[cell.key]) {
          return (
            <div>{format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}</div>
          )
        }

        return '-'
      }
      case 'scheduler_end_date': {
        if (row[cell.key]) {
          return (
            <div>{format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')}</div>
          )
        }

        return '-'
      }
      case 'status': {
        if (row[cell.key]) {
          return (
            <StatusButton
              bgColor={getStatusColor(row[cell.key])}
              text={convertBlockUnderscoreTextIntoCamelCaseWithSpace(
                row[cell.key] as string
              )}
            />
          )
        }

        return '-'
      }
      default:
        break
    }

    return ''
  }

  return (
    <PageTemplate>
      <div className={classes.searchBlock}>
        <SearchField
          clickFilterSearch={(val, isChange) => {
            if (isChange) {
              setFilterQuery((prevState) => ({
                ...prevState,
                searchText: val
              }))
            }
          }}
          placeholder={`${t('notificationHistory.searchPlaceholderText')}`}
        />
        <Button
          className={classes.searchBtn}
          color='primary'
          onClick={() => {
            navigate('/notifications/create-notification')
          }}
        >
          <AddIcon />
          {t('notificationHistory.addNew')}
        </Button>
      </div>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('notificationHistory.headerTitle')}`} (
            {notificationHistoryList?.data?.total_notifications ?? 0})
          </Typography>
        </div>
        {notificationHistoryList?.data?.total_notifications === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('notificationHistory.noNotificationsFound')}
            </p>
            <p className={classes.noAvailableText}>
              {t('notificationHistory.noItemsFound')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table
                type='multi-line'
                tableWidth='1530px'
                dataRows={
                  notificationHistoryList
                    ? notificationHistoryList.data?.notifications
                    : []
                }
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                isSuccess={notificationHistoryListIsSuccess}
                isReloading={notificationHistoryListIsFetching}
                skeletonProps={columnProps}
                columnProps={columnProps}
                cellDataGenerator={cellDataGenerator}
                hyperlink={{
                  valid: true,
                  path: PAGE_PATH_NAME.NOTIFICATION_DETAILS,
                  state: "notification_id"
                }}
              />
            </SimpleBar>
            {notificationHistoryListIsFetching &&
            notificationHistoryListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    notificationHistoryList?.data?.total_notifications === 1
                      ? '10%'
                      : notificationHistoryList?.data?.total_notifications ===
                        2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  (notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={onClickPrevPage}
                clickNextPageButton={onClickNextPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={
                  (currentPage + 1) * rowsPerPage >
                  (notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0)
                    ? notificationHistoryList
                      ? notificationHistoryList.data?.total_notifications
                      : 0
                    : (currentPage + 1) * rowsPerPage
                }
                totalDataRows={
                  notificationHistoryList
                    ? notificationHistoryList.data?.total_notifications
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default NotificationHistoryList
