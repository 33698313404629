/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import * as React from 'react'
import { Typography } from '@mui/material'
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps
} from 'react-select'
import useCustomSelectStyles from './style'
import { ptColors } from '../../../themes/settings/colors'
import { ReactComponent as ArrowDown } from '../../../images/down-arrow.svg'
import { ReactComponent as CheckIcon } from '../../../images/icon/Right_blue.svg'
import { DropdownErrorType, OptionsType } from '../../../types/common'

export type CustomSelectProps = {
  selectedOption: OptionsType | undefined
  optionChangeHandler: (Option: OptionsType) => void
  options: OptionsType[]
  defaultValue?: OptionsType
  isDropdownError?: DropdownErrorType
  labelText?: string
  required?: boolean
  name?: string
  disabled?: boolean
}

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const classes = useCustomSelectStyles()
  // customize react-select
  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <ArrowDown className={classes.selectArrow} />
    </components.DropdownIndicator>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.isSelected && <CheckIcon />}
    </components.Option>
  )

  // for react select medium start
  const customStylesMd = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      background: `${ptColors.white}`,
      minHeight: '38px',
      height: '38px',
      borderRadius: '6px',
      boxShadow: state.isFocused ? '0px 0px 1px #9AA5B1' : null,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: props.disabled ? "#FAFAFA !important": "#FFFFFF !important",
      // eslint-disable-next-line no-nested-ternary
      border: state.isFocused
        ? '1px solid #9AA5B1'
        : props.isDropdownError?.isError
        ? `1px solid #FF0000`
        : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: `${ptColors.santaGrey}`
      },
      '&:focus': {
        borderColor: `${ptColors.santaGrey}`
      },
    }),

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    valueContainer: (provided: any) => ({
      ...provided,
      height: '38px',
      padding: '0 16px',
      marginTop: '-1px'
    }),

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    input: (provided: any, state: unknown) => ({
      ...provided,
      margin: '0px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    indicatorSeparator: (state: unknown) => ({
      display: 'none'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    indicatorsContainer: (provided: any, state: unknown) => ({
      ...provided,
      height: '38px',
      marginTop: '-1px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    menu: (provided: any, state: unknown) => ({
      ...provided,
      borderRadius: '4px'
      // boxShadow: '0px 3px 20px #00000029'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    option: (provided: any, state: any) => ({
      ...provided,
      height: '37px',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundColor: state.isSelected ? '#FAFAFA' : '#fff',
      color: state.isSelected ? `${ptColors.ebonyClay}` : '#6C7778',
      ':active': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        backgroundColor: state.isSelected ? '#FAFAFA' : '#fff'
      },
      ':first-of-type': {
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px'
      },
      ':last-child': {
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px'
      }
    })
  }

  // for react select medium end

  return (
    <>
      <Typography className={classes.label}>
        {props.labelText}
        {props.required && <span className={classes.required}> *</span>}
      </Typography>
      <Select
        isDisabled = {props.disabled}
        isSearchable={false}
        value={props.selectedOption}
        onChange={(option) => props.optionChangeHandler(option as OptionsType)}
        options={props.options}
        components={{ DropdownIndicator, Option }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        className={classes.reactSelect}
        classNamePrefix='react-select'
        styles={customStylesMd}
        filterOption={() => true}
        defaultValue={props.defaultValue}
        name={props.name}
      />
    </>
  )
}

export default CustomSelect
