/* eslint-disable padding-line-between-statements */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useRef, useState } from 'react'
import './index.scss'
import { IDate, IDatepickerProps } from '../../../types/datepicker/index'
import DatepickerTime from './Time'
import DatepickerHeader from './Header'
import DatepickerCalendar from './Calendar'
import DatepickerFooter from './Footer'

const Datepicker: React.FC<IDatepickerProps> = ({
  width = '100%',
  onDateChange,
  value = null,
  disabled = false,
  min = null,
  max = null
}) => {
  // eslint-disable-next-line no-console
  const current = {
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    hours: new Date().getHours(),
    minutes: new Date().getMinutes()
  }
  const setInitialDate = (
    param: 'date' | 'month' | 'year' | 'hours' | 'minutes'
  ) => {
    switch (param) {
      case 'date': {
        if (value) {
          if (min) {
            return new Date(min).getDate()
          }
          return new Date(value).getDate()
        }
        return current.date
      }

      case 'month': {
        if (value) {
          if (min) {
            return new Date(min).getMonth()
          }
          return new Date(value).getMonth()
        }
        return current.month
      }

      case 'year': {
        if (value) {
          if (min) {
            return new Date(min).getFullYear()
          }
          return new Date(value).getFullYear()
        }
        return current.year
      }

      case 'hours': {
        if (value) {
          if (min) {
            return new Date(min).getHours()
          }
          return new Date(value).getHours()
        }
        return current.hours
      }
      case 'minutes': {
        if (value) {
          if (min) {
            return new Date(min).getMinutes()
          }
          return new Date(value).getMinutes()
        }
        return current.minutes
      }

      default:
        break
    }

    return 0
  }
  const [menuDisplayDate, setMenuDisplayDate] = useState<IDate>({
    date: setInitialDate('date'),
    month: setInitialDate('month'),
    year: setInitialDate('year'),
    hours: setInitialDate('hours'),
    minutes: setInitialDate('minutes')
  })
  const [selectedDate, setSelectedDate] = useState<IDate | null>(
    value
      ? {
          date: value ? new Date(value).getDate() : current.date,
          month: value ? new Date(value).getMonth() : current.month,
          year: value ? new Date(value).getFullYear() : current.year,
          hours: value ? new Date(value).getHours() : current.hours,
          minutes: value ? new Date(value).getMinutes() : current.minutes
        }
      : null
  )
  const menuRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [applyDisabled, setApplyDisabled] = useState<boolean>(true)
  
  const [hoursRange, setHoursRange] = useState<{ start: number; end: number }>({
    start: min ? min.getHours() : 0,
    end: max ? max.getHours() : 24
  })
  const [minutesRange, setMinuteRange] = useState<{
    start: number
    end: number
  }>({
    start: min ? min.getMinutes() : 0,
    end: max ? max.getMinutes() : 60
  })

  useEffect(() => {
    if (disabled) {
      return
    }
    const handleClickOutside = (event: Event) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        setOpen(!open)
      }
      if (open && menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(!open)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, setOpen, open, disabled])

  useEffect(() => {
    if (value) {
      setSelectedDate({
        date: new Date(value).getDate(),
        month: new Date(value).getMonth(),
        year: new Date(value).getFullYear(),
        hours: new Date(value).getHours(),
        minutes: new Date(value).getMinutes()
      })
    } else {
      setSelectedDate(null)
    }
  }, [value])

  
  useEffect(() => {
    if (selectedDate) {
      if (
        new Date(
          `${menuDisplayDate?.year}-${menuDisplayDate?.month}-${menuDisplayDate?.date} ${menuDisplayDate?.hours}:${menuDisplayDate?.minutes}`
        ).getTime() ===
        new Date(
          `${selectedDate?.year}-${selectedDate?.month}-${selectedDate?.date} ${selectedDate?.hours}:${selectedDate?.minutes}`
        ).getTime()
      ) {
        setApplyDisabled(true)
      } else {
        setApplyDisabled(false)
      }
    } else {
      setApplyDisabled(false)
    }
  }, [menuDisplayDate, selectedDate])

  const onNext = () => {
    if (menuDisplayDate.month === 11) {
      return setMenuDisplayDate((prevState) => ({
        ...prevState,
        date: 1,
        month: 0,
        year: menuDisplayDate.year + 1
      }))
    }

    return setMenuDisplayDate((prevState) => ({
      ...prevState,
      date: 1,
      month: menuDisplayDate.month + 1
    }))
  }

  const onPrev = () => {
    if (menuDisplayDate.year === 1970 && menuDisplayDate.month === 0) {
      return
    }
    if (menuDisplayDate.month === 0) {
      return setMenuDisplayDate((prevState) => ({
        ...prevState,
        date: 1,
        month: 11,
        year: menuDisplayDate.year - 1
      }))
    }
    return setMenuDisplayDate((prevState) => ({
      ...prevState,
      date: 1,
      month: menuDisplayDate.month - 1
    }))
  }

  const onDateClick = (
    year: number,
    month: number,
    date: number,
    hours: number,
    minutes: number
  ) => {
    setMenuDisplayDate((prevState) => ({
      ...prevState,
      date,
      month,
      year,
      hours,
      minutes
    }))
    if (min && max) {
      if (
        new Date(`${year}-${month + 1}-${date}`).getTime() ===
        new Date(
          `${min.getFullYear()}-${min.getMonth() + 1}-${min.getDate()}`
        ).getTime()
      ) {
        setHoursRange({ start: min.getHours(), end: 23 })
        setMinuteRange({ start: min.getMinutes(), end: 60 })
      } else if (
        new Date(`${year}-${month + 1}-${date}`).getTime() ===
        new Date(
          `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`
        ).getTime()
      ) {
        setHoursRange({ start: 0, end: max.getHours() + 1 })
        setMinuteRange({ start: 0, end: max.getMinutes() })
      } else {
        setHoursRange({ start: 0, end: 24 })
        setMinuteRange({ start: 0, end: 60 })
      }
    } else if (min && !max) {
      if (
        new Date(`${year}-${month + 1}-${date}`).getTime() ===
        new Date(
          `${min.getFullYear()}-${min.getMonth() + 1}-${min.getDate()}`
        ).getTime()
      ) {
        setHoursRange({ start: min.getHours(), end: 23 })
        setMinuteRange({ start: min.getMinutes(), end: 60 })
      } else {
        setHoursRange({ start: 0, end: 24 })
        setMinuteRange({ start: 0, end: 60 })
      }
    } else if (!min && max) {
      if (
        new Date(`${year}-${month + 1}-${date}`).getTime() ===
        new Date(
          `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`
        ).getTime()
      ) {
        setHoursRange({ start: 0, end: max.getHours() + 1 })
        setMinuteRange({ start: 0, end: max.getMinutes() })
      } else {
        setHoursRange({ start: 0, end: 24 })
        setMinuteRange({ start: 0, end: 60 })
      }
    } else if (!min && !max) {
      setHoursRange({ start: 0, end: 24 })
      setMinuteRange({ start: 0, end: 60 })
    }
  }

  const onTimeClickHour = (hours: number) => {
    setMenuDisplayDate((prevState) => ({ ...prevState, hours }))
    if (min) {
      if (
        new Date(
          `${menuDisplayDate.year}-${menuDisplayDate.month + 1}-${
            menuDisplayDate.date
          }`
        ).getTime() ===
        new Date(
          `${min.getFullYear()}-${min.getMonth() + 1}-${min.getDate()}`
        ).getTime()
      ) {
        if (min.getHours() === hours) {
          setMinuteRange({ start: min.getMinutes(), end: 60 })
        } else {
          setMinuteRange({ start: 0, end: 60 })
        }
      }
    }
    if (max) {
      if (
        new Date(
          `${menuDisplayDate.year}-${menuDisplayDate.month + 1}-${
            menuDisplayDate.date
          }`
        ).getTime() ===
        new Date(
          `${max.getFullYear()}-${max.getMonth() + 1}-${max.getDate()}`
        ).getTime()
      ) {
        if (max.getHours() === hours) {
          setMinuteRange({ start: 0, end: max.getMinutes() })
        } else {
          setMinuteRange({ start: 0, end: 60 })
        }
      }
    }
  }

  const onTimeClickMinute = (minutes: number) => {
    setMenuDisplayDate((prevState) => ({ ...prevState, minutes }))
  }

  const handleConfirm = () => {
    onDateChange(
      new Date(
        menuDisplayDate.year,
        menuDisplayDate.month,
        menuDisplayDate.date,
        menuDisplayDate.hours,
        menuDisplayDate.minutes
      )
    )
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div className='datepicker'>
      <input
        style={{
          width,
          backgroundColor: disabled ? '#FAFAFA' : '#fff',
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? '#939598' : '#232F3E'
        }}
        ref={inputRef}
        type='text'
        className='datepicker_input'
        value={
          selectedDate
            ? `${selectedDate.year}/${String(selectedDate.month + 1).padStart(
                2,
                '0'
              )}/${String(selectedDate.date).padStart(2, '0')} ${String(
                selectedDate.hours
              ).padStart(2, '0')}:${String(selectedDate.minutes).padStart(
                2,
                '0'
              )}`
            : ''
        }
        placeholder='Set the date'
        readOnly
      />
      {open ? (
        <div className='datepicker_modal' ref={menuRef}>
          <DatepickerHeader
            dateObj={menuDisplayDate}
            onNext={onNext}
            onPrev={onPrev}
          />
          <DatepickerCalendar
            onDateClick={onDateClick}
            dateObj={menuDisplayDate}
            currentDateObj={current}
            min={min}
            max={max}
          />
          <DatepickerTime
            onTimeClickHour={onTimeClickHour}
            onTimeClickMinute={onTimeClickMinute}
            dateObj={menuDisplayDate}
            hoursRange={hoursRange}
            minutesRange={minutesRange}
          />
          <DatepickerFooter
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            dateObj={
              // min
              //   ? {
              //       date: new Date(min).getDate(),
              //       month: new Date(min).getMonth(),
              //       year: new Date(min).getFullYear(),
              //       hours: new Date(min).getHours(),
              //       minutes: new Date(min).getMinutes()
              //     }
              //   :
              menuDisplayDate
            }
            disabled={applyDisabled}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Datepicker
