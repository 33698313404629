/* eslint-disable camelcase */

import { useSelector } from "react-redux";
import { authInfoSlice, AuthInfoState } from "../state/slice/authInfoSlice";
import { AppDispatch, RootState, useAppDispatch } from "../state/store";


const useAuthHook = () => {
    const dispatch: AppDispatch = useAppDispatch();
    
    const userInfo = useSelector<RootState, AuthInfoState>(
    (state) => state?.iotAuthInfo,
    );

    const {
    saveName,
    saveGivenName,
    saveFamilyName,
    savePicture,
    saveEmail,
    saveLocale,
    saveAuthId,
    saveAuthName,
    saveAccessToken
    } = authInfoSlice.actions

    const AuthInfoReset = () => {   
        dispatch(saveName(''));
        dispatch(saveGivenName(''));
        dispatch(saveFamilyName(''));
        dispatch(savePicture(''));
        dispatch(saveEmail(''));
        dispatch(saveLocale(''));
        dispatch(saveAuthId(''));
        dispatch(saveAuthName(''));
        dispatch(saveAccessToken(''));
    }
    

    const isRegisted = () => {

        if (userInfo?.accessToken.charAt(0) === ' ') {
            AuthInfoReset();

            return false;
        }
        if (!userInfo?.accessToken) {
            AuthInfoReset();
            
            return false;
        }
        if (!userInfo) {
            AuthInfoReset();

            return false;
        }
        
        return true;
    }

    return {
        userInfo,
        isRegisted,
        AuthInfoReset,
    };
};

export default useAuthHook;
