import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { makeStyles } from '@mui/styles'
import PageTemplate from '../PageTemplate'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: '10px',
    backgroundColor: '#ffffff',
    padding: '32px',
    borderRadius: '8px'
  },
  info: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: '40px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: '6px'
  },
  chip: {
    height: '25px !important',
    padding: '3px 0px 5px !important'
  },

  chipColorFadedGreen: {
    color: 'white !important',
    backgroundColor: '#8ab165 !important'
  },
  chipColorNiceBlue: {
    color: 'white !important',
    backgroundColor: '#007EB4 !important'
  }
}))

type TextProps = {
  children: React.ReactNode
  /* eslint-disable*/
  color?: string
  fontWeight?: string
  fontSize?: string
  lineHeight?: string
}
const Text = ({
  children,
  color = '#232f3e',
  fontSize = '14px',
  fontWeight = '400',
  lineHeight = '26px'
}: TextProps) => (
  <Typography
    color={color}
    textAlign='left'
    fontSize={fontSize}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
  >
    {children}
  </Typography>
)

const CorporateDetails = () => {
  const classes = useStyles()

  return (
    <PageTemplate>
      <Box
        sx={{
          backgroundColor: '#EAEDED',
          padding: '20px'
        }}
      >
        <Box className={classes.container}>
          <Box className={classes.info}>
            <Box className={classes.element}>
              <Text fontWeight='700'>Corporate ID</Text>
              <Text>00000001</Text>
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Corporate Name</Text>
              <Text>YamaCorp.KK</Text>
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Name</Text>
              <Text>Yamashita</Text>
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Address</Text>
              <Text>
                105-0000 1-1-1, Higashi Sinbashi, Minato-ku Tokyo, Japan
              </Text>
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Telephone</Text>
              <Text>03-0000-0000</Text>
            </Box>
          </Box>
          <Box className={classes.info}>
            <Box className={classes.element}>
              <Text fontWeight='700'>User Count</Text>
              <Text color='#007eb4'>5</Text>
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Device Count</Text>
              <Text color='#007eb4'>10</Text>
            </Box>
          </Box>
          <Box className={classes.info}>
            <Box className={classes.element}>
              <Text fontWeight='700'>Plan</Text>
              <Chip
                label='Enterprise'
                className={`${classes.chip} ${classes.chipColorNiceBlue}`}
                sx={{
                  '.MuiChip-label': {
                    padding: '0px 10px 1px',
                    fontSize: '12px',
                    lineHeight: '24px'
                  }
                }}
              />
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Status</Text>
              <Chip
                label='All Complete'
                className={`${classes.chip} ${classes.chipColorFadedGreen}`}
                sx={{
                  '.MuiChip-label': {
                    padding: '0px 10px 1px',
                    fontSize: '12px',
                    lineHeight: '24px'
                  }
                }}
              />
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Use Start Date</Text>
              <Text>Oct 17, 2022</Text>
            </Box>
          </Box>
          <Box className={classes.info}>
            <Box className={classes.element}>
              <Text fontWeight='700'>Sales Name</Text>
              <Box
                sx={{
                  border: '1px solid #DDDDDD',
                  borderRadius: '4px',
                  padding: '9px 12px',
                  minWidth: '190px'
                }}
              >
                <Text lineHeight='14px'>Kris Wesley</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  )
}

export default CorporateDetails
