/**
 * table header の型
 */
export type TableHeaderData = {
  // キー
  key: string
  // ヘッダーに表示する値
  value: string
  // ソート可否(true=ソート可能/false=ソート不可)
  isSort: boolean
  // 表示幅
  width?: string
}

/**
 * table row の型
 */
export type TableRowData = {
  // 行のid(keyとして指定。keyを指定しないとコンソールにWarningが出る)
  id: string | number
  // 行に表示する内容
  data: { [key: string | number]: string | number }
  // 行をグレーアウトするか否か(true=する/false=しない)
  isGrayOut?: boolean
}

/**
 * table TableRowColWidth の型
 */
export type TableRowColWidth = {
  [key: string | number]: { colWidth: number; colMaxWidth: number }
}

/**
 * 各一覧画面の表示内容の状態
 */
export type DisplayContentState = {
  // 一覧取得API呼び出し状態
  apiCallState: string
  // フィルタリング状態
  filteringState: string
}

/**
 * 各一覧取得API呼び出し状態
 */
export const API_CALL_STATE = {
  // API呼び出し前
  pending: 'pending',
  // API呼び出し成功
  fulfilled: 'fulfilled',
  // API呼び出し失敗
  rejected: 'rejected'
} as const
export type API_CALL_STATE = typeof API_CALL_STATE[keyof typeof API_CALL_STATE]

/**
 * フィルタリング状態
 */
export const FILTERING_STATE = {
  // フィルタリング中
  active: 'active',
  // フィルタリング解除
  inactive: 'inactive',
  // フィルタリング結果なし（1つもなかった）
  noting: 'noting'
} as const
export type FILTERING_STATE =
  typeof FILTERING_STATE[keyof typeof FILTERING_STATE]

/**
 * ソートタイプ
 */
export const DIRECTION_TYPE = {
  // 昇順
  ascending: 'ASC',
  // 降順
  descending: 'DESC',
  // ソートなし
  none: ''
} as const
export type DIRECTION_TYPE = typeof DIRECTION_TYPE[keyof typeof DIRECTION_TYPE]

/**
 * ソート設定
 */
export type SortConfig = {
  // ソート対象のキー
  key: string
  // ソートタイプ
  direction: DIRECTION_TYPE
}

/**
 * ［端末一覧］画面用テーブルデータ(key)
 */
export const CORPORATION_TABLE_DATA_KEY = {
  ID: 'corp_id',
  CORPORATION_NAME: 'company',
  STATUS: 'status_code',
  STATUS_COLOR: 'color_code_name',
  START_DATE: 'created_dt'
} as const
export type CORPORATION_TABLE_DATA_KEY =
  typeof CORPORATION_TABLE_DATA_KEY[keyof typeof CORPORATION_TABLE_DATA_KEY]

export const DEVICE_TABLE_DATA_KEY = {
  ID: 'corp_id',
  CORPORATION_NAME: 'company',
  IMEI1: 'imei1',
  IMEI2: 'imei2',
  DEVICE_MODEL: 'product_type',
  ICCID: 'iccid',
  SIM_EXP_DATE: 'sim_exp_dt',
  START_DATE: 'created_dt'
} as const
export type DEVICE_TABLE_DATA_KEY =
  typeof CORPORATION_TABLE_DATA_KEY[keyof typeof CORPORATION_TABLE_DATA_KEY]

/**
 * ［翻訳回数］画面用テーブルデータ(key)
 */
export const TRANS_COUNT_TABLE_DATA_KEY = {
  TRANS_COUNT: 'transCount',
  IMEI: 'imei',
  DEVICE_NAME: 'deviceName',
  PRODUCT_TYPE: 'productType',
  ICCID: 'iccid',
  COUNT_LIST: 'countList'
} as const
export type TRANS_COUNT_TABLE_DATA_KEY =
  typeof TRANS_COUNT_TABLE_DATA_KEY[keyof typeof TRANS_COUNT_TABLE_DATA_KEY]

/**
 * ［翻訳元言語］画面用テーブルデータ(key)
 */
export const FROM_LANG_TABLE_DATA_KEY = {
  USE_COUNT: 'useCount',
  LANG: 'lang',
  COUNT_LIST: 'countList'
} as const
export type FROM_LANG_TABLE_DATA_KEY =
  typeof FROM_LANG_TABLE_DATA_KEY[keyof typeof FROM_LANG_TABLE_DATA_KEY]

/**
 * ［翻訳後言語］画面用テーブルデータ(key)
 */
export const TO_LANG_TABLE_DATA_KEY = {
  USE_COUNT: 'useCount',
  LANG: 'lang',
  COUNT_LIST: 'countList'
} as const
export type TO_LANG_TABLE_DATA_KEY =
  typeof TO_LANG_TABLE_DATA_KEY[keyof typeof TO_LANG_TABLE_DATA_KEY]

/**
 * ［翻訳履歴］画面用テーブルデータ(key)
 */
export const TRANS_HISTORY_TABLE_DATA_KEY = {
  TRANSDT: 'transdt',
  FROM_TEXT: 'fromText',
  TO_TEXT: 'toText',
  FROM_LANG: 'fromLang',
  TO_LANG: 'toLang'
} as const
export type TRANS_HISTORY_TABLE_DATA_KEY =
  typeof TRANS_HISTORY_TABLE_DATA_KEY[keyof typeof TRANS_HISTORY_TABLE_DATA_KEY]

/**
 * ［ユーザー管理］画面用テーブルデータ(key)
 */
export const CORPORATION_STATUS = {
  COMPLETE: 'complete',
  PARTIALLY: 'partially',
  IN_USE: 'in_use'
} as const
export type CORPORATION_STATUS =
  typeof CORPORATION_STATUS[keyof typeof CORPORATION_STATUS]

/**
 * ビュー切替が「日」の場合のヘッダー幅
 */
export const TITLE_DATE_HEADER_WIDTH = '130px' as const
/**
 * ビュー切替が「週」の場合のヘッダー幅
 */
export const TITLE_WEEK_HEADER_WIDTH = '185px' as const
/**
 * ビュー切替が「月」の場合のヘッダー幅
 */
export const TITLE_MONTH_HEADER_WIDTH = '105px' as const

/**
 * [Group Management] screen table data (key)
 */
export const GROUP_LIST_TABLE_DATA_KEY = {
  GROUP_NAME: 'group_name',
  NUMBER_OF_USERS: 'number_of_account',
  NUMBER_OF_DEVICE: 'number_of_device',
  LAST_UPDATED: 'modifieddt',
  GROUP_ID: 'groupId',
  GROUP_CODE: 'groupCode'
} as const

/**
 * [Group Management] screen table data (key)
 */
export const GROUP_DEVICE_LIST_TABLE_DATA_KEY = {
  DEVICE_NAME: 'deviceName',
  DEVICE_MODEL: 'productType',
  ICC_ID_NO: 'iccid',
  DEVICE_NUMBER_IMEI: 'imei',
  SIM_EXP_DATE: 'simAccount',
  CREATED_DATE: 'createdDt',
  LAST_USED: 'latestTransdt'
} as const

export type GROUP_LIST_TABLE_DATA_KEY =
  typeof GROUP_LIST_TABLE_DATA_KEY[keyof typeof GROUP_LIST_TABLE_DATA_KEY]
