/* eslint-disable import/prefer-default-export */
// import dayjs from 'dayjs';

// import type { DeviceInfoData } from '../../types/devicelist/deviceList.d';
// import type { TransCountInfo } from '../../types/apis/transCountListApi';
// import type { FromLangInfo } from '../../types/apis/fromLangListApi';
// import type { ToLangInfo } from '../../types/apis/toLangListApi';
// import type { TransHistoryInfo } from '../../types/apis/transHistoryListApi.d';
// import type { UserInfo } from '../../types/apis/userListApi.d';
import {
  TableRowData,
  SortConfig,
  DIRECTION_TYPE,
  TableHeaderData,
  TITLE_DATE_HEADER_WIDTH,
} from '../../types/datatable';
import { containString } from '../utility';
// import { VIEW_KEY } from '../../types/viewswitch/viewSwitch.d';
// import { GroupInfo } from '../../types/apis/groupListApi.d';
// import { GroupDeviceListInfo } from '../../types/apis/groupDeviceListApi.d';
/**
 * データテーブルに依存する汎用クラス
 */

/**
 * 端末一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalDeviceInfos コピー対象の端末一覧
 * @returns DataTableRow型の配列
 */
// export const convertDeviceInfoArrayToTableRowDataArray = (
//   originalDeviceInfos: DeviceInfoData[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   Object.entries(originalDeviceInfos).forEach((entry) => {
//     const [, deviceInfo] = entry;

//     Object.keys(deviceInfo).forEach((imei) => {
//       const { expiredt } = deviceInfo[imei];
//       const newData: TableRowData = {
//         id: imei,
//         data: {
//           [DEVICE_TABLE_DATA_KEY.IMEI]: deviceInfo[imei].imei,
//           [DEVICE_TABLE_DATA_KEY.DEVICE_NAME]: deviceInfo[imei].deviceName,
//           // [DEVICE_TABLE_DATA_KEY.LATEST_TRANSDT]:
//           //   deviceInfo[imei].latestTransdt,
//           [DEVICE_TABLE_DATA_KEY.EXPIREDT]:
//             expiredt !== undefined ? expiredt : '',
//           [DEVICE_TABLE_DATA_KEY.REGISTDT]: deviceInfo[imei].registdt,
//           [DEVICE_TABLE_DATA_KEY.PRODUCT_TYPE]: deviceInfo[imei].productType,
//           // [DEVICE_TABLE_DATA_KEY.ICCID]: deviceInfo[imei].iccid,
//           [DEVICE_TABLE_DATA_KEY.MODE]: deviceInfo[imei].modeName,

//           [DEVICE_TABLE_DATA_KEY.STATUS]: deviceInfo[imei].modeStatus,
//           [DEVICE_TABLE_DATA_KEY.STATUS_COLOR]: deviceInfo[imei].statusColor,
//         },
//         isGrayOut: deviceInfo[imei].isDeregister,
//       };
//       dataArray.push(newData);
//     });
//   });

//   return dataArray;
// };

/**
 * 翻訳回数一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalTransCountInfos コピー対象の翻訳回数一覧
 * @returns DataTableRow型の配列
 */
// export const convertTransCountInfoArrayToTableRowDataArray = (
//   originalTransCountInfos: TransCountInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalTransCountInfos.forEach((info: TransCountInfo) => {
//     const newData: TableRowData = {
//       id: info.imei,
//       data: {
//         [TRANS_COUNT_TABLE_DATA_KEY.IMEI]: info.imei,
//         [TRANS_COUNT_TABLE_DATA_KEY.ICCID]: info.iccid,
//         [TRANS_COUNT_TABLE_DATA_KEY.DEVICE_NAME]: info.deviceName,
//         [TRANS_COUNT_TABLE_DATA_KEY.PRODUCT_TYPE]: info.productType,
//         [TRANS_COUNT_TABLE_DATA_KEY.TRANS_COUNT]: info.transCount.toString(),
//       },
//       isGrayOut: info.isDeregister,
//     };
//     for (let i = 0; i < info.countList.length; i += 1) {
//       newData.data[`${TRANS_COUNT_TABLE_DATA_KEY.COUNT_LIST}${i}`] =
//         info.countList[i];
//     }
//     dataArray.push(newData);
//   });

//   return dataArray;
// };

/**
 * 翻訳元言語一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalFromLangInfos コピー対象の翻訳元言語一覧
 * @returns DataTableRow型の配列
 */
// export const convertFromLangInfoArrayToTableRowDataArray = (
//   originalFromLangInfos: FromLangInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalFromLangInfos.forEach((info: FromLangInfo) => {
//     const newData: TableRowData = {
//       id: info.fromlang,
//       data: {
//         [FROM_LANG_TABLE_DATA_KEY.LANG]: info.fromlang,
//         [FROM_LANG_TABLE_DATA_KEY.USE_COUNT]: info.fromlangCount.toString(),
//       },
//     };
//     for (let i = 0; i < info.countList.length; i += 1) {
//       newData.data[`${FROM_LANG_TABLE_DATA_KEY.COUNT_LIST}${i}`] =
//         info.countList[i];
//     }
//     dataArray.push(newData);
//   });

//   return dataArray;
// };

/**
 * 翻訳後言語一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalToLangInfos コピー対象の翻訳後言語一覧
 * @returns DataTableRow型の配列
 */
// export const convertToLangInfoArrayToTableRowDataArray = (
//   originalToLangInfos: ToLangInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalToLangInfos.forEach((info: ToLangInfo) => {
//     const newData: TableRowData = {
//       id: info.tolang,
//       data: {
//         [TO_LANG_TABLE_DATA_KEY.LANG]: info.tolang,
//         [TO_LANG_TABLE_DATA_KEY.USE_COUNT]: info.tolangCount.toString(),
//       },
//     };
//     for (let i = 0; i < info.countList.length; i += 1) {
//       newData.data[`${TO_LANG_TABLE_DATA_KEY.COUNT_LIST}${i}`] =
//         info.countList[i];
//     }
//     dataArray.push(newData);
//   });

//   return dataArray;
// };

/**
 * 翻訳履歴一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalHistoryInfos 変換対象の翻訳履歴一覧
 * @returns DataTableRow型の配列
 */
// export const convertTransHistoryArrayToTableDataArray = (
//   originalHistoryInfos: TransHistoryInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   for (let i = 0; i < originalHistoryInfos.length; i += 1) {
//     const newData: TableRowData = {
//       id: i.toString(), // 一意となるキーがないためインデックス番号設定(警告対策なので何かに使用するわけではない)
//       data: {
//         [TRANS_HISTORY_TABLE_DATA_KEY.TRANSDT]: originalHistoryInfos[i].transdt,
//         [TRANS_HISTORY_TABLE_DATA_KEY.FROM_TEXT]:
//           originalHistoryInfos[i].fromText,
//         [TRANS_HISTORY_TABLE_DATA_KEY.TO_TEXT]: originalHistoryInfos[i].toText,
//         [TRANS_HISTORY_TABLE_DATA_KEY.FROM_LANG]:
//           originalHistoryInfos[i].fromLang,
//         [TRANS_HISTORY_TABLE_DATA_KEY.TO_LANG]: originalHistoryInfos[i].toLang,
//       },
//     };
//     dataArray.push(newData);
//   }

//   return dataArray;
// };

/**
 * ユーザー一覧を、1ページに表示するデータ型の配列に変換して返却
 *
 * @param originalUserInfos 変換対象のユーザー一覧
 * @returns DataTableRow型の配列
 */
// export const convertUserListArrayToTableDataArray = (
//   originalUserInfos: UserInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalUserInfos.forEach((info: UserInfo) => {
//     const newData: TableRowData = {
//       id: info.mailAddress,
//       data: {
//         [USER_LIST_TABLE_DATA_KEY.USER_NAME]: info.userName,
//         [USER_LIST_TABLE_DATA_KEY.MAIL_ADDRESS]: info.mailAddress,
//         [USER_LIST_TABLE_DATA_KEY.AUTH_NAME]: info.authName,
//       },
//     };
//     dataArray.push(newData);
//   });

//   return dataArray;
// };

/**
 * 指定されたテーブルデータをソート
 *
 * @param targetTableData ソート対象のリスト
 * @param crrentSortConfig ソート情報(key=ソート対象のプロパティ名, direction=ソート指定(昇順 or 降順))
 * @returns ソート後のリスト
 */
export const sortedItems = (
  targetTableData: TableRowData[],
  crrentSortConfig: SortConfig,
): TableRowData[] => {
  const sortableItems: TableRowData[] = [...targetTableData];

  // ソートなしの場合は何もしない
  if (crrentSortConfig.direction === DIRECTION_TYPE.none) {
    return sortableItems;
  }

  // 指定された key で昇順または降順でソート
  sortableItems.sort((a, b) => {
    // 文字列と数字が混在した場合でもソートできるように変換
    // 文字列で判定して、末尾が数字の場合は、後ろの桁から判断してソートされる
    const aValue = String(a.data[crrentSortConfig.key]).replace(/(\d+)/g, (m) =>
      m.padStart(30, '0'),
    );
    const bValue = String(b.data[crrentSortConfig.key]).replace(/(\d+)/g, (m) =>
      m.padStart(30, '0'),
    );

    // ソート
    if (aValue < bValue) {
      return crrentSortConfig.direction === DIRECTION_TYPE.ascending ? -1 : 1;
    }
    if (bValue < aValue) {
      return crrentSortConfig.direction === DIRECTION_TYPE.ascending ? 1 : -1;
    }

    return 0;
  });

  return sortableItems;
};

/**
 * ソート設定を更新
 *
 * @param key ソート対象のkey
 * @param crrentSortConfig 現在のソート設定
 * @returns 更新後のソート設定。undefined=リセット
 */
export const updateSortConfig = (
  key: string,
  crrentSortConfig: SortConfig,
): SortConfig => {
  if (
    crrentSortConfig.direction === DIRECTION_TYPE.none ||
    crrentSortConfig.key !== key
  ) {
    // 初回ソート
    return { key, direction: DIRECTION_TYPE.ascending };
  }

  switch (crrentSortConfig.direction) {
    case DIRECTION_TYPE.ascending:
      // すでに昇順でソート済み
      return { key, direction: DIRECTION_TYPE.descending };

    case DIRECTION_TYPE.descending:
      return { key: '', direction: DIRECTION_TYPE.none };
    default:
      return { key, direction: DIRECTION_TYPE.ascending };
  }
};

/**
 * 指定された検索文字列でフィルタリング
 *
 * @param list フィルタリング対象の配列
 * @param searchText 検索文字列
 * @param filterOutKeys フィルタリング対象外のkey配列
 * @returns
 */
export const filterArray = <T>(
  list: T[],
  searchText: string,
  filterOutKeys: string[],
): T[] => {
  // 念のためチェック
  if (!searchText) {
    return list;
  }

  // フィルタリング
  const resultArray: T[] = list.filter((value) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    containString(value, searchText, filterOutKeys),
  );

  return resultArray;
};

/**
 * 指定された配列(APIから返却されたヘッダを想定)をTableHeaderData型に変換
 *
 * @param targetHeaders APIから返却されたヘッダ
 * @param tableHeaderKey ヘッダのkey
 * @param viewKey ビュー切替キー
 * @returns
 */
export const convertStringArrayToTableHeaderColumns = (
  targetHeaders: string[],
  tableHeaderKey: string,
  viewKey?: string,
): TableHeaderData[] => {
  if (targetHeaders === null || targetHeaders.length <= 0) {
    return [];
  }
  // ヘッダの表示幅(ビュー切替キーごとに設定)
  let width = '';
  if (viewKey !== undefined) {
    switch (viewKey) {
      // case VIEW_KEY.w:
      //   width = TITLE_WEEK_HEADER_WIDTH;
      //   break;
      // case VIEW_KEY.m:
      //   width = TITLE_MONTH_HEADER_WIDTH;
      //   break;
      default:
        width = TITLE_DATE_HEADER_WIDTH;
    }
  }

  // ヘッダ変換
  const titleHeaderColumns: TableHeaderData[] = [];
  for (let i = 0; i < targetHeaders.length; i += 1) {
    titleHeaderColumns.push({
      key: `${tableHeaderKey}${i}`,
      value: targetHeaders[i],
      isSort: true,
      width,
    });
  }

  return titleHeaderColumns;
};

// export const convertGroupListArrayToTableDataArray = (
//   originalGroupInfos: GroupInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalGroupInfos.forEach((info: GroupInfo) => {
//     const newData: TableRowData = {
//       id: info.groupId,
//       data: {
//         [GROUP_LIST_TABLE_DATA_KEY.GROUP_NAME]: info.groupName,
//         /* eslint-disable @typescript-eslint/no-unsafe-assignment */
//         [GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_USERS]: info.numberOfAccount,
//         [GROUP_LIST_TABLE_DATA_KEY.NUMBER_OF_DEVICE]: info.numberOfDevice,
//         [GROUP_LIST_TABLE_DATA_KEY.LAST_UPDATED]: dayjs(info.modifieddt).format(
//           'MMM DD, YYYY',
//         ),
//         [GROUP_LIST_TABLE_DATA_KEY.GROUP_CODE]: info.groupCode,
//       },
//     };
//     dataArray.push(newData);
//   });

//   return dataArray;
// };

// export const convertGroupDeviceListArrayToTableDataArray = (
//   originalGroupInfos: GroupDeviceListInfo[],
// ): TableRowData[] => {
//   const dataArray: TableRowData[] = [];
//   originalGroupInfos.forEach((info: GroupDeviceListInfo) => {
//     const newData: TableRowData = {
//       id: info.imei,
//       data: {
//         [GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NUMBER_IMEI]: info.imei,
//         [GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NAME]: info.deviceName,
//         [GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_MODEL]: info.deviceModel,

//         // [GROUP_DEVICE_LIST_TABLE_DATA_KEY.ICC_ID_NO]: info.iccid,
//         // [GROUP_DEVICE_LIST_TABLE_DATA_KEY.SIM_EXP_DATE]: info.simAccount,
//         [GROUP_DEVICE_LIST_TABLE_DATA_KEY.CREATED_DATE]: dayjs(
//           info.groupAddedDate,
//         ).format('MMM DD, YYYY'),
//         // [GROUP_DEVICE_LIST_TABLE_DATA_KEY.LAST_USED]: info.latestTransdt,
//       },
//     };
//     dataArray.push(newData);
//   });

//   return dataArray;
// };
