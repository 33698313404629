/* eslint-disable react/no-array-index-key */
import useMediaQuery from '@mui/material/useMediaQuery';
import { Skeleton, Box } from '@mui/material';
import {useStyles} from '../../../themes';

interface ISkeletonProp{
  colWidth: number
  [key: string]: unknown
}

interface ISkeletonProps{
  skeletonProps: ISkeletonProp[]
  gap?: string
}

const LoadingSkeleton:React.FC<ISkeletonProps> = ({skeletonProps,gap}): JSX.Element => {
  const classes = useStyles()
  const matches = useMediaQuery('(min-height: 800px)');

  return (
    <>
    {Array.from({ length: matches ? 11 : 7 }, (_, k) => (
      <Box key={k} style={{ gap: gap ? gap : '42px' }} className={classes.tableDataRowSkeleton}>
        {skeletonProps.map((element, index)=>(
            <Box key={index} className={classes.col} width={element.colWidth}>
              <Skeleton
                variant='rectangular'
                height={20}
              />
            </Box>
          )
        )}
      </Box>
    ))}
  </>
  )
}

export default LoadingSkeleton
