import { makeStyles } from '@mui/styles'
import { ptColors } from '../../../themes/settings/colors'

const useCustomSelectStyles = makeStyles(() => ({
  // customize react select option list start
  reactSelect: {
    // marginBottom: 13,
    '& .react-select__single-value': {
      color: `${ptColors.ebonyClay}`
    },
    '& .react-select__placeholder': {
      color: '#6C7778'
    },
    '& .react-select__indicator': {
      paddingRight: '12px'
    },
    '& .react-select__menu': {
      margin: '0',
      boxShadow: '0px 3px 20px #00000029',
      zIndex: 1000,
      '& .react-select__menu-list': {
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '148px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '& .react-select__option': {
          padding: '10px 12px',
          width: '100%',
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#fafafa',
            color: '#232F3E'
          }
        },
        '& .react-select__option:not(:last-child)': {
          borderBottom: '0.5px solid #ddd'
        }
      }
    },
    '& .react-select__single-value--is-disabled': {
      color: '#939598'
    }
  },
  label: {
    fontSize: 14,
    color: `${ptColors.ebonyClay}`,
    lineHeight: '19px',
    letterSpacing: '0.09px',
    marginBottom: 6
  },
  required: {
    color: `${ptColors.colorRed}`,
    opacity: '80%'
  },
  selectArrow: {
    marginTop: '2px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5
    }
  }
}))

export default useCustomSelectStyles
