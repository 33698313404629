import ReactDOM from 'react-dom';
import 'simplebar-react/dist/simplebar.min.css';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from './App';
import store from './state/store';
// import mockServiceWorker from './mocks/browser';

// fonts
import '@fontsource/noto-sans-jp';
import '@fontsource/noto-sans-jp/300.css';
import '@fontsource/noto-sans-jp/500.css';
import '@fontsource/noto-sans-jp/700.css';
import { GOOGLE_CLIENT_ID } from './constants/constants';

// mock起動
// if (process.env.NODE_ENV === 'development') {
//   void mockServiceWorker.start();
// }

// ReduxフレームワークのStore利用準備
const persistor = persistStore(store);

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID??GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HttpsRedirect>,
  document.getElementById('root'),
);
