/* eslint-disable arrow-body-style */
/* eslint-disable radix */
import { ITimeProps } from '../../../../types/datepicker/index'
import { arrayFill } from '../../../../utils/utility'

const DatepickerTime: React.FC<ITimeProps> = ({
  dateObj,
  onTimeClickHour,
  onTimeClickMinute,
  hoursRange,
  minutesRange,
}) => {

  return (
    <div className="datepicker_modal_time">
      <select
        onChange={(e) => onTimeClickHour(parseInt(e.target.value))}
        defaultValue={dateObj.hours}
      >
        {arrayFill(hoursRange.start, hoursRange.end).map((hour) => (
          <option key={hour} value={hour}>
            {hour < 10 ? `0${hour}` : hour}
          </option>
        ))}
      </select>
      <span className="time-divider">:</span>
      <select
        onChange={(e) => onTimeClickMinute(parseInt(e.target.value))}
        defaultValue={dateObj.minutes}
      >
        {arrayFill(minutesRange.start, minutesRange.end).map((minute) => (
          <option key={minute} value={minute}>
            {minute < 10 ? `0${minute}` : minute}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DatepickerTime
