/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import FormErrorMessage from '../FormErrorToolTip'
import inputStyles from './style'

export interface ITextAreaProps {
  label?: string
  placeholder?: string
  register: UseFormRegister<FieldValues>
  registerField: string
  required: boolean
  pattern?: {
    pattern: RegExp
    errorMessage: string
  }
  errors: {
    [x: string]: {
      message: string
    }
  }
  errorMessage?: string
  disabled?: boolean
  min?: number
  max?: number
  labelIcon?: React.ReactNode
  labelIconTooltipText?: string
}

const TextArea: React.FC<ITextAreaProps> = ({
  label = '',
  placeholder = '',
  errors,
  register,
  registerField,
  required,
  pattern,
  disabled,
  min,
  max,
  labelIcon,
  labelIconTooltipText
}) => {
  const styles = inputStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.container_label}>
        {label}
        {required ? <span className={styles.container_asterisk}>*</span> : null}
        {labelIcon && (
          <Tooltip
            arrow
            placement='top'
            title={<p>{labelIconTooltipText}</p>}
            sx={{ marginTop: 0 }}
            componentsProps={{
              tooltip: {
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0 20px',
                  fontWeight: 400,
                  fontSize: '14px',
                  backgroundColor: '#37475A',
                  lineHeight: '2',
                  whiteSpace: 'pre-wrap'
                }
              }
            }}
          >
            <span className={styles.labelTooltipIcon}>{labelIcon}</span>
          </Tooltip>
        )}
      </div>
      <FormErrorMessage
        isError={!!errors[registerField]?.message}
        message={errors[registerField]?.message}
      >
        <textarea
          {...register(registerField, {
            required: {
              value: required,
              message: `${t('commonErrorMessage.required')}`
            },
            pattern: {
              value: pattern?.pattern ?? /(.*?)/,
              message: pattern?.errorMessage ?? ''
            },
            minLength: {
              value: min ? min : 0,
              message: `${t('commonMessage.minInput')} ${min ? min : 0} ${t(
                'commonMessage.characters'
              )}`
            },
            maxLength: {
              value: max ? max : Number.MAX_VALUE,
              message: `${t('commonMessage.maxInput')} ${
                max ? max : Number.MAX_VALUE
              } ${t('commonMessage.characters')}`
            }
          })}
          disabled={disabled}
          placeholder={placeholder}
          className={
            errors[registerField]
              ? styles.container_textarea_error
              : styles.container_textarea
          }
        />
      </FormErrorMessage>
    </div>
  )
}

export default TextArea
