import {
  Drawer,
  styled,
  Box,
  Theme,
  CSSObject,
} from '@mui/material';
import MainMenu from './MainMenu';
import { useStyles } from '../../themes/index';

// assets

const drawerWidth = 250;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border: '0px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '250px',
  border: '0px',
});
const MyDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
const Sidebar = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();
  // メインメニュー hooks

  return (
    <MyDrawer variant="permanent" anchor="left">
      <Box className={classes.drawerBox}>
        <MainMenu />
      </Box>
    </MyDrawer>
  );
};

export default Sidebar;
