/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, MouseEvent } from 'react';
import * as Material from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ptColors } from '../../../themes/settings/colors';

export type TooltipProps = {
  toolTipTitle?: string | number;
  maxWidth?: number;
};

const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const useStyles = makeStyles(() => ({
    toolTipContent: {
      maxWidth: `${props.maxWidth ?? ''}px`,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      color: `${ptColors.ebonyClay}`,
      fontFamily: "'Noto Sans JP', sans-serif",
      height: '30px',
      lineHeight: '30px'
    },
  }));

  const classes = useStyles();

  const [tooltipEnabledGroup, setTooltipEnabledGroup] = useState(false);


  const handleShouldShowTooltip = (event: MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;

    const clone = element.cloneNode(true) as HTMLElement;

    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';

    element.parentNode?.appendChild(clone);

    element.style.overflow = 'visible';

    element.parentNode?.removeChild(clone);

    element.style.overflow = 'hidden';
    if (event.currentTarget.scrollWidth > event.currentTarget.clientWidth) {
      setTooltipEnabledGroup(true);
    } else {
      setTooltipEnabledGroup(false);
    }
  };

  const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Material.Tooltip
      placement="top"
      title={props.toolTipTitle ?? ''}
      onMouseEnter={handleShouldShowTooltip}
      disableHoverListener={!tooltipEnabledGroup}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        onClick: handleTooltipClick,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: '240px',
            padding: '8px 15px 9px',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: '12px',
            backgroundColor: '#37475A',
            lineHeight: '17px',
            // whiteSpace: 'pre-wrap',
            whiteSpace: 'normal',
          },
        },
      }}
    >
      <div className={classes.toolTipContent}>{props.toolTipTitle}</div>
    </Material.Tooltip>
  );
};

export default CustomTooltip;
