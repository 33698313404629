/* eslint-disable */
import React, { useState } from 'react'
import {
  FormControl,
  OutlinedInput,
  InputAdornment
  // IconButton
} from '@mui/material'

import { ReactComponent as SearchIcon } from '../../../images/icon/search.svg'
// common style
import { useStyles } from '../../../themes'

type Props = {
  placeholder?: string
  clickFilterSearch: (val: string, isChange?: boolean) => void
}

const SearchField = React.memo<Props>(
  ({ clickFilterSearch, placeholder = '' }): JSX.Element => {
    // common styles
    const classes = useStyles()

    const [searchText, setSearchText] = useState('')
    const [isChange, setIsChange] = useState(false)

    React.useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        clickFilterSearch(encodeURIComponent(searchText), isChange)
      }, 300)

      return () => clearTimeout(delayDebounceFn)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
      setIsChange(true)
    }

    // const debouncedAPICall = useCallback(
    //   const val = clickFilterSearch(encodeURIComponent(e.target.value))
    //   debounce(val), 300),
    //   []
    // )

    return (
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          // Special characters need to be encoded in order to pass through params
          clickFilterSearch(encodeURIComponent(searchText))
        }}
      >
        <FormControl variant='outlined'>
          <OutlinedInput
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            className={classes.searchInputFilter}
            value={searchText}
            onChange={onChangeHandler}
            placeholder={placeholder}
            endAdornment={
              <InputAdornment position='end'>
                {/* <IconButton
                  type='submit'
                  sx={{ p: '8px', marginLeft: '8px' }}
                  aria-label='search'
                >
                  
                </IconButton> */}
                <SearchIcon style={{ width: '12.01px', height: '12.01px' }} />
              </InputAdornment>
            }
            aria-describedby='outlined-weight-helper-text'
            inputProps={{ 'aria-label': 'search', maxLength: 256 }}
          />
        </FormControl>
      </form>
    )
  }
)

export default SearchField
