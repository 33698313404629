/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import './index.scss'

const iconList = [
  'corporation',
  'device',
  'import',
  'usergroup',
  'notification'
]
type IconType = (typeof iconList)[number]

export interface IconProps {
  children?: React.ReactNode
  iconName: IconType
  mask?: boolean
  className?: string
}

export const Icon = ({ iconName, mask, className = '' }: IconProps) => {
  const componentClassName = 'a-icon'

  return (
    <span
      className={`${componentClassName} ${`${componentClassName}--${iconName}`} ${
        mask && `${componentClassName}--${iconName}-mask`
      } ${className && className}`}
    />
  )
}
