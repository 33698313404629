/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PAGE_PATH_NAME } from '../../constants/constants'

const baseQuery = fetchBaseQuery({
  baseUrl: `${
    process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'
  }/iotconsoleapi-bo/api`,
  prepareHeaders: (headers, { getState }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const token = getState().iotAuthInfo.accessToken
    if (token) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      headers.set('Authorization', `Bearer ${token}`)
    }

    return headers
  }
})

const apiSlice = createApi({
  reducerPath: 'api',

  baseQuery: async (args, api, extraOptions) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await baseQuery(args, api, extraOptions)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (result?.error?.data?.result_code === 'ERR_INVALID_TOKEN_JWT') {
      localStorage.clear()
      window.location.href = PAGE_PATH_NAME.LOGIN
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result
  },
  tagTypes: [],
  endpoints: () => ({})
})

export type apiSliceType = typeof apiSlice
export default apiSlice
