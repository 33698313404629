/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDeviceListResponse, deviceSearchParamType } from "../../../types/device";
import apiSlice from "../../api/apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["DeviceList"],
});

export const deviceSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    deviceList: builder.query<IDeviceListResponse, deviceSearchParamType>({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection
      }: any) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `private/devicelist?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}`,
        method: "GET",
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ["DeviceList"],
    }),
    deviceListSearch: builder.mutation<any, any>({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        sortColName,
        sortDirection
      }: deviceSearchParamType) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `private/devicelist?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}`,
        method: 'GET',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
        // body: queryParam
      })
    }),
    deleteDevice: builder.mutation<any, any>({
      query: ({ device_id }) => ({
        url: `/private/device-delete?serialInfoId=${device_id}`,
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['DeviceList']
    })
  })
})

export const { useDeviceListQuery, useLazyDeviceListQuery, useDeviceListSearchMutation, useDeleteDeviceMutation } = deviceSlice;
