/* eslint-disable  */
import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import {ReactComponent as ArrowRightIcon} from '../../../images/icon/arrowRight.svg'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon  />}
    {...props}
  />
))(({ theme }) => ({
  padding: '0 2px !important',
  minHeight: 'unset',
  marginBottom: '7px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
    marginLeft: theme.spacing(1),
    '& .MuiTypography-root': {
      fontSize: '12px',
      lineHeight: '16px'
    }
  }
}))

type CustomizedAccordionsProps = {
    title: string
    children: React.ReactNode
}

export default function CustomizedAccordions({title, children}: CustomizedAccordionsProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const handleChange =
    (panel: boolean) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded)
    }

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleChange(true)}>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <Typography>{title}</Typography>
        </AccordionSummary>
        {children}
      </Accordion>
    </div>
  )
}
