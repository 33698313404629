import { Navigate, RouteProps } from 'react-router-dom';
import { PAGE_PATH_NAME } from '../../constants/constants';
import useAuthHook from '../../hooks/useAuth';

interface PrivateRouteProps extends RouteProps {
    element: JSX.Element;
}

const PrivateRoute = ({ element }: PrivateRouteProps) => {
   const { isRegisted } = useAuthHook();

    return isRegisted() ? (
        element
    ) : (
        <Navigate to={PAGE_PATH_NAME.LOGIN} replace />
    );
};

export default PrivateRoute;
