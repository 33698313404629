/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUserListResponse, UserListSearchParamType } from '../../../types/users';
import apiSlice from '../../api/apiSlice';

const apiWithTags = apiSlice.enhanceEndpoints({
    addTagTypes: ["UserList"],
});

export const userSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        inviteUser: builder.mutation<any, any>({
            query: (body) => ({
                url: '/private/inviteuser',
                method: 'POST',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                body,
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: ["UserList"],
        }),
        verifyBOOneTimeKey: builder.query<any, any>({
            query: (body) => ({
                url: '/auth/verifyboonetimekey',
                method: 'POST',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                body,
            }),
        }),
        login: builder.mutation<any, any>({
            query: (body) => ({
                url: '/authenticate',
                method: 'POST',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                body,
            }),
        }),
        userList: builder.query<IUserListResponse, UserListSearchParamType>({
            query: ({
                searchText,
                currentPageNumber,
                rowLimitPerPage,
                colName,
                sortDirection,
            }) => ({
                url: `/private/userlist?searchText=${searchText as string}&currentPageNumber=${currentPageNumber as number}&rowLimitPerPage=${rowLimitPerPage as number}&colName=${colName || ""}&sortDirection=${sortDirection as string}`,
                method: "GET",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["UserList"],
        }),
        deleteUser: builder.mutation<any,any>({
            query: ({
                boUserId
            }) => ({
                url: `/private/deleteuser?boUserId=${boUserId as number}`,
                method: "DELETE",
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json'
                },
            }),
            invalidatesTags: ["UserList"],
        })
    }),
});

export const { useInviteUserMutation, useVerifyBOOneTimeKeyQuery, useLoginMutation, useUserListQuery, useLazyUserListQuery, useDeleteUserMutation } =
    userSlice;
