/* eslint-disable */
import React from 'react'
import { useEffect, useState, useRef } from 'react'
import {Avatar, Box, Typography, Button, ListItemButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import PageTemplate from '../PageTemplate'
import AddUserDialog from '../../Modecules/AddUser'
import SearchField from '../../Atoms/SearchInput'
import CustomButton from '../../Atoms/Buttons'
import { ReactComponent as AddIcon } from '../../../images/icon/addIcon.svg'
import { useStyles } from '../../../themes';
import SimpleBar from 'simplebar-react'
import Table from '../../Templates/Table'
import Pagination from '../../Modecules/Pagination/Pagination'
import { useDeleteUserMutation,  useInviteUserMutation, useLazyUserListQuery, useUserListQuery } from '../../../state/features/user/userSlice'
import { TABLE_ROW_NUMBER_PER_PAGE } from '../../../constants/constants'
import usePagination from '../../../hooks/usePagination'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import { IColumnProp, IColumnProps } from '../../../types/table'
import CustomTooltip from '../../Atoms/Tooltip'
import StatusButton from '../../Atoms/StatusButton'
import { convertBlockUnderscoreTextIntoCamelCaseWithSpace } from '../../../utils/utility'
import { format } from 'date-fns'
import Loader from '../../Atoms/Loader/Loader'
import { ReactComponent as BlueCheck } from '../../../images/icon/blue-check.svg'
import { ReactComponent as MenuIcon } from '../../../images/icon/menu.svg'
import { ReactComponent as Delete } from '../../../images/icon/delete.svg'
import { ReactComponent as DeleteIconModal } from '../../../images/icon/modal-delete.svg'
import { IUserResponse } from '../../../types/users'
import CommonDialog from '../../Atoms/Dialog'
import { ReactComponent as Users } from '../../../images/icon/corporation-data-not-found.svg'
import useAuthHook from '../../../hooks/useAuth'

const UserManagement = (): JSX.Element => {
  const [addUserModal, setAddUserModal] = useState<boolean>(false)
  const [deleteUserModal, setDeleteUserModal] = useState<{state: boolean, boUserId: number}>({state: false, boUserId: 0})
  const [response, setResponse] = useState<{
    status: boolean
    message: string
  } | null>(null)
  const [reinviteMail, setReinviteMail] = useState<string>("")

  const classes = useStyles()
  // const classes2 = useStyles2()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const { t } = useTranslation()
  useEffect(() => {
    if (response && response.status) {
      setAddUserModal(false)
      toast.success(response.message)
    } else if (response && !response.status) {
      setAddUserModal(false)
      toast.error(response.message)
    }
  }, [response])

  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    sortDirection: 'DESC',
    colName: 'invitedDt',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0,
  });

  const [trigger, {
      data: userList,
      isSuccess: userListIsSuccess,
      isFetching: userListIsReloading
    }] = useLazyUserListQuery()

  const [
    inviteUser,
    {
      data: inviteUserData,
      isSuccess: inviteUserSuccess,
      isError: inviteUserError,
      isLoading: inviteUserIsLoading
    }
  ] = useInviteUserMutation();

  const [
    deleteUser,
    {
      data: deleteUserData,
      isSuccess: deleteUserSuccess,
      isError: deleteUserError,
      isLoading: deleteUserIsLoading,
      error: deleteUserErrorData
    }
  ] = useDeleteUserMutation();

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 0, userList ? userList?.data.total_users : 0)

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.searchText, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))

  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (inviteUserSuccess) {
      toast.success(`The user's re-invitation has been successfully sent to ${reinviteMail}`)
    }
    if (inviteUserError) {
      toast.error("Failed to re-invite user")
    }
  }, [inviteUserSuccess, inviteUserError])

  const sortFieldsNameReplace = (substr: string) => {
    // username | mailAddress | inviteeName | invitedDt | registeredDt | lastActiveDt | statusCode
    if (substr === 'username') {
      return 'username'
    }
    if (substr === 'mail_address') {
      return 'mailAddress'
    }
    if (substr === 'invitee_name') {
      return 'inviteeName'
    }
    if (substr === 'invited_dt') {
      return 'invitedDt'
    }
    if (substr === 'registered_dt') {
      return 'registeredDt'
    }
    if (substr === 'status_code') {
      return 'statusCode'
    }

    return ''
  }

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(
      key,
      sortConfig
    )
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection: sortConfigUpdate.key === "" ? "DESC" : sortConfigUpdate.direction,
      colName: sortConfigUpdate.direction === DIRECTION_TYPE.none ? "invitedDt" :sortFieldsNameReplace(key),
    })
    changeCurrentPage(0)
  }

  // start delete User
  const [openPopperId, setOpenPopperId] = useState<string | null>(null)
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false)
  const buttonRefs = useRef<Array<React.RefObject<HTMLButtonElement>>>([])

  const handleDeleteMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    event.stopPropagation()

    if (openPopperId === id && isPopperOpen) {
      setIsPopperOpen(!openPopperId)
    } else {
      setIsPopperOpen(true)
    }
    setOpenPopperId(id)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the event target is not inside any of the buttonRefs
      if (
        !buttonRefs.current.some((ref) =>
          ref.current?.contains(event.target as Node)
        )
      ) {
        // Clicked outside any of the buttons
        setOpenPopperId(null)
        setIsPopperOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [buttonRefs])

  useEffect(() => {
    if (deleteUserSuccess) {
      toast.success('User Successfully Deleted.')
    }
    if (deleteUserError) {
      if (deleteUserErrorData.data?.message) {
        toast.error(deleteUserErrorData.data.message)
      } else {
        toast.error('Failed to Delete User.')
      }
    }
  }, [deleteUserSuccess, deleteUserError])

  const confirmDelete = () => {
    deleteUser({ boUserId: deleteUserModal.boUserId })
    setDeleteUserModal({ state: false, boUserId: 0 })
  }
  // end delete User

  const columnProps: IColumnProps = [
    {
      key: "username",
      name: "Username",
      colWidth: 200,
      colMaxWidth: 200,
      isSort: true
    },
    {
      key: "mail_address",
      name: "Email Address",
      colWidth: 200,
      colMaxWidth: 200,
      isSort: true
    },
    {
      key: "invitee_name",
      name: "Invitee Name",
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: "invited_dt",
      name: "Invitation Date",
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: "registered_dt",
      name: "Reg. Date",
      colWidth: 130,
      colMaxWidth: 130,
      isSort: true
    },
    {
      key: "status_code",
      name: "Status",
      colWidth: 85,
      colMaxWidth: 85,
      isSort: true
    },
    {
      key: "reinvite",
      name: "Re-Invite",
      colWidth: 80,
      colMaxWidth: 80,
    },
    {
      key: "delete",
      name: '',
      colWidth: 58,
      colMaxWidth: 58
    }
  ]

  const skeletonProps = [
    { width: 200 },
    { width: 200 },
    { width: 130 },
    { width: 130 },
    { width: 130 },
    { width: 85 },
    { width: 80 },
    { width: 58 }
  ]

  const getStatus = (status: string) => {
    switch (status) {
      case "INVITED":
        return "#9AA5B1"
      case "RE_INVITED":
        return "#9AA5B1"
      case "REGISTERED":
        return "#007EB4"
      case "PENDING":
        return "#FFAD33"
      default:
        break;
    }
    // row.status_code ===  ? "#9AA5B1" : "#007EB4"
  }

  const cellDataGenerator = (
    cell: IColumnProp,
    row: IUserResponse,
    index: number
  ) => {
    // Create a ref for each button
    buttonRefs.current[index] =
      buttonRefs.current[index] || React.createRef<HTMLButtonElement>()

    switch (cell.key) {
      case "username": {
        if (row[cell.key]) {
          return (
            <div style={{display: "flex", gap: "12px"}}>
              {row.avatar_url?
                <Avatar sx={{height: "30px", width: "30px"}} src={row.avatar_url}/>:
                <Avatar sx={{
                    height: "30px", width: "30px",
                    bgcolor:"#37475A",
                    fontSize: "15px",
                    fontWeight: "500"
                  }}>{row?.first_name.charAt(0).toUpperCase()}</Avatar>
              }
              <CustomTooltip
                toolTipTitle={row[cell.key]}
                maxWidth={cell.colMaxWidth}
              />
            </div>
          );
        }

        return "-"
      }

      case "mail_address": {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          );
        }

        return "-"
      }

      case "invitee_name": {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          );
        }

        return "-"
      }

      case "invited_dt": {
        if (row[cell.key]) {
          return format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm');
        }

        return "-"
      }

      case "registered_dt": {
        if (row[cell.key]) {
          return format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm');
        }

        return "-"
      }

      case "status_code": {
        if (row[cell.key]) {
          return (<StatusButton
              // bgColor={row.status_code === "INVITED" ? "#9AA5B1" : "#007EB4"}
              bgColor={getStatus(row.status_code)}
              text={convertBlockUnderscoreTextIntoCamelCaseWithSpace(row.status_code ? row.status_code : "")}
            />)
        }

        return "-"
      }
      case "reinvite": {
        if (row["status_code"] ==="REGISTERED") {
          return null
        } else {
          return <span style={{ color: "#007EB4", fontSize: "12px", display: "flex", alignItems: 'center', gap: "6px", cursor: "pointer" }} onClick={() => {
                setReinviteMail(row["mail_address"])
                inviteUser({
                  first_name: row["first_name"],
                  last_name: row["last_name"],
                  mail_address: row["mail_address"],
                  bo_account_master_id: 1, // later it will be replaced with logged in user id at sp 10
                  language_master_id: 1,
                  is_reinvited: true
                })
              }}><BlueCheck />Re-Invite</span>;
        }
      }
      case "delete": {
        return (
          <Box className={classes.deleteIconBox}>
            <Button
              id='deleteButton'
              variant='contained'
              className={classes.deleteIconBtn}
              aria-controls={`simple-popper-${row['mail_address']}`}
              aria-describedby={`simple-popper-${row['mail_address']}`}
              aria-haspopup='true'
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteMenuOpen(event, row['mail_address'])
              }}
              ref={buttonRefs.current[index]} // Use the ref corresponding to the current row
            >
              <MenuIcon />
            </Button>
            {openPopperId === row['mail_address'] && isPopperOpen && (
              <Box className={classes.deletePopper}>
                <ListItemButton
                  disableRipple
                  onClick={() => {
                    setDeleteUserModal({
                      state: true,
                      boUserId: row['user_id']
                    })
                  }}
                  className={classes.deletePopperBtn}
                >
                  <Delete />
                  <p style={{ padding: '0', margin: '0' }}>Delete</p>
                </ListItemButton>
              </Box>
            )}
          </Box>
        )
      }

      default:
        break;
    }

    return ""
  }
  


  return (
    <PageTemplate>
      <div className={classes.searchBlock}>
        <SearchField
          clickFilterSearch={(val, isChange) => {
            if(isChange){
              setFilterQuery((prevState) => ({
                ...prevState,
                searchText: val
              }))
            }
          }}
          placeholder={`${t('userManagement.searchPlaceholderText')}`}
        />
        <CustomButton
          className={classes.searchBtn}
          color='primary'
          onClick={() => setAddUserModal(true)}
        >
          <AddIcon />
          {t('userManagement.addUser')}
        </CustomButton>
      </div>
      <AddUserDialog
        handleClose={() => {
          setAddUserModal(false)

        }}
        open={addUserModal}
        setResponse={setResponse}
      />
      <CommonDialog
        open={deleteUserModal.state}
        handleClose={() => {
          setDeleteUserModal({ state: false, boUserId: 0 })
        }}
        handleSubmit={() => {
          confirmDelete()
        }}
        icon={DeleteIconModal}
        title={'Are you sure you want to delete this User?'}
        description={'This action cannot be undone.'}
        cancelText={'Cancel'}
        successText={'Delete'}
        isModalTop
      />
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('userManagement.headerTitle')}`}  ({userList?.data?.total_users ?? 0})
          </Typography>
        </div>

        {
          userList?.data?.total_users === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Users />
            <p className={`${classes.noFountText} ${classes.mt_0}`}>
              {t('userManagement.noUserFound')}
            </p>
            <p className={`${classes.noAvailableText} ${classes.mt_0}`} >
              {t('userManagement.currentlyNoDevices')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table
                tableWidth='1318px'
                dataRows={userList ? userList.data?.users : []}
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                isSuccess={userListIsSuccess}
                isReloading={
                  userListIsReloading ||
                  inviteUserIsLoading ||
                  deleteUserIsLoading
                }
                skeletonProps={columnProps}
                columnProps={columnProps}
                cellDataGenerator={cellDataGenerator}
              />
            </SimpleBar>
            {
                (userListIsReloading || inviteUserIsLoading || deleteUserIsLoading) && userListIsSuccess ?
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top:
                    userList?.data?.total_users === 1
                      ? '10%'
                      : userList?.data?.total_users === 2
                      ? '25%'
                      : '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box> : null
              }
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil((userList ? userList.data?.total_users : 0) / rowsPerPage)}
                clickPrevPageButton={onClickPrevPage}
                clickNextPageButton={onClickNextPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={(currentPage + 1) * rowsPerPage >
                  (userList ? userList.data?.total_users : 0)
                    ? (userList ? userList.data?.total_users : 0)
                    : (currentPage + 1) * rowsPerPage}
                totalDataRows={(userList ? userList.data?.total_users : 0)}
              />
            </div>
          </Box>
        )}
      </Box>
    </PageTemplate>
  )
}

export default UserManagement
