/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import SimpleBarReact from 'simplebar-react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography
} from '@mui/material'
// import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-gray.svg'
import { ReactComponent as ArrowUp } from '../../../images/icon/Arrow_up.svg'
import { ReactComponent as ArrowDown } from '../../../images/icon/Arrow_down.svg'
import Button from '../../Atoms/Buttons/index'
import Loader from '../../Atoms/Loader/Loader'
import {
  emailPreviewDialogStyles,
  CustomEmailPreviewDialog
} from '../EmailPreview/style'

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose?: () => void
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const classes = emailPreviewDialogStyles()
  const { children, onClose } = props

  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.iconBtn}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const renderContent = (body: string) => {
  const paragraphs = body.trim()
    .split('\n')
    .map((paragraph, index) => <p key={index}>{paragraph !== ""? paragraph : '\u00A0'}</p>)

  const linkRegex = /{([^{}|]+)\|([^{}|]+)}/g
  const parsedContent = paragraphs.map((paragraph, index) => {
    const nodes = []
    let lastIndex = 0
    let match

    while ((match = linkRegex.exec(paragraph.props.children)) !== null) {
      const text = match[1]
      const link = match[2]

      // Add the text preceding the link
      nodes.push(paragraph.props.children.slice(lastIndex, match.index))

      // Add the link
      nodes.push(
        <a
          style={{ color: '#009DE1', textDecoration: 'none' }}
          key={index + '-' + match.index}
          href={link}
        >
          {text}
        </a>
      )

      lastIndex = match.index + match[0].length
    }

    // Add the remaining text after the last link
    nodes.push(paragraph.props.children.slice(lastIndex))

    // Return the updated paragraph with links
    return React.cloneElement(paragraph, { key: index }, nodes)
  })

  return parsedContent
}

export interface dialogProps {
  open: boolean
  handleClose: () => void
  title: string
  body: string
  date: string
}

const BellPreviewDialog = ({
  open = true,
  handleClose,
  title,
  body,
  date
}: dialogProps) => {
  const classes = emailPreviewDialogStyles()
  const [viewLess, setViewLess] = useState<boolean>(false)
  const containerRef = useRef<any>(null)
  const [showViewLess, setShowViewLess] = useState<boolean>(false)


  return (
    <div style={{ position: 'relative' }}>
      <CustomEmailPreviewDialog
        onClose={() => handleClose()}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        {false && (
          <Box className={classes.loaderParent}>
            <Loader colorCode='#939598' />
          </Box>
        )}
        <CustomDialogTitle
          id='customized-dialog-title'
          // onClose={() => handleClose()}
        >
          <Box className={classes.dialogTitle}>
            <Typography
              fontSize='20px'
              fontWeight='medium'
              margin='0'
              lineHeight='27px'
              color='#313133'
              letterSpacing='0.13px'
              paddingLeft='32px'
            >
              Bell Preview
            </Typography>
          </Box>
        </CustomDialogTitle>

        <DialogContent>
          <SimpleBarReact className={classes.dialogContentWrapper}>
            <Box className={classes.dialogContentBodyBell}>
              <Box className={classes.dialogBodyCard}>
                <Box className={classes.dialogCardHeader}>
                  <Typography className={classes.dialogCardHeaderText}>
                    Only show Unread
                  </Typography>
                  <Box className={classes.toggleBtn} />
                </Box>
                <Box className={classes.dialogCardBody}>
                  <Box className={classes.dialogTab}>
                    <Box className={classes.dialogTabItem}>Activity</Box>
                    <Box className={classes.dialogTabItemActive}>
                      Announcements
                    </Box>
                  </Box>
                  <Box className={classes.dialogCardContent}>
                    <Typography className={classes.dialogCardTitle}>
                      {title.trim()}
                    </Typography>
                    <Box
                      ref={containerRef}
                      className={classes.dialogCardText}
                      style={viewLess ? { maxHeight: 'unset' } : {}}
                    >
                        {renderContent(body)}
                    </Box>
                    <Typography
                      className={`${classes.dialogCardText} ${classes.dialogCardDate}`}
                    >
                      {`${new Date(date).getFullYear()}-${
                        new Date(date).getMonth() + 1 < 10
                          ? '0' + String(new Date(date).getMonth() + 1)
                          : String(new Date(date).getMonth() + 1)
                      }-${
                        new Date(date).getDate() < 10
                          ? '0' + String(new Date(date).getDate())
                          : new Date(date).getDate()
                      } ${
                        new Date(date).getHours() < 10
                          ? '0' + String(new Date(date).getHours())
                          : new Date(date).getHours()
                      }:${
                        new Date(date).getMinutes() < 10
                          ? '0' + String(new Date(date).getMinutes())
                          : new Date(date).getMinutes()
                      }`}
                      {/* {showViewLess ? (
                        <button
                          onClick={() => {setViewLess(!viewLess)}}
                          className={classes.viewText}
                        >
                          {viewLess ? (
                            <>
                              View Less <ArrowUp />
                            </>
                          ) : (
                            <>
                              View Details
                              <ArrowDown />
                            </>
                          )}
                        </button>
                      ) : null} */}
                      <button onClick={()=>setViewLess(!viewLess)} className={classes.viewText} >{viewLess? <>View Less <ArrowUp/></>: <>View Details<ArrowDown/></>}</button>
                    </Typography>
                  </Box>
                  <Box className={classes.dialogCardSkeleton}>
                    <Box className={classes.skeletonMd} />
                    <Box className={classes.skeletonLg} />
                    <Box className={classes.skeletonSm} />
                  </Box>
                  <Box className={classes.dialogCardSkeleton}>
                    <Box className={classes.skeletonMd} />
                    <Box className={classes.skeletonLg} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </SimpleBarReact>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <div className={classes.actions_row}>
            <div className={classes.actions_row_buttons}>
              <Button onClick={() => handleClose()} color='primary'>
                Close
              </Button>
            </div>
          </div>
        </DialogActions>
      </CustomEmailPreviewDialog>
    </div>
  )
}

export default BellPreviewDialog
