/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable */

import React from 'react'
import {
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  DialogContent,
  Box,
  Button,
  ListItemText,
  Divider
} from '@mui/material'
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps
} from 'react-select'
import SimpleBarReact from 'simplebar-react'
import { DeviceDialog, useDeviceImportStyles } from './style'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-gray.svg'
import { ReactComponent as DownloadIcon } from '../../../images/icon/download.svg'
import { ReactComponent as ArrowDown } from '../../../images/down-arrow.svg'
import { ReactComponent as CheckIcon } from '../../../images/icon/Right_blue.svg'
import { ReactComponent as ShowMoreIcon } from '../../../images/icon/show-more-down.svg'
import { ReactComponent as ShowUpIcon } from '../../../images/icon/show-more-up.svg'
import FromErrorMessage from '../../Atoms/FormErrorToolTip'

import CustomizedAccordions from '../Accordion'
import FileInput from '../../Atoms/fileInput'
import { useCorporationListDeviceQuery } from '../../../state/features/corporations/corporationsSlice'
import { mapKeysCamelCase } from '../../../utils/callApiUtil'
import { useRegisterDeviceMutation } from '../../../state/features/deviceimport/deviceImportSlice'
import Loader from '../../Atoms/Loader/Loader'
import { toast } from 'react-toastify'
import { ptColors } from '../../../themes/settings/colors'
import { t } from 'i18next'
import CustomTooltip from '../../Atoms/Tooltip'

type DeviceImportDialogProps = {
  onClose: () => void
  open: boolean
}

type OptionsType = {
  label: string
  value: string
}

type CustomDialogTitleProps = {
  children: React.ReactNode
  onClose: () => void
}

const DeviceImportDialog = ({ onClose, open }: DeviceImportDialogProps) => {
  const classes = useDeviceImportStyles()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  const [options, setOptions] = React.useState<OptionsType[]>([])
  const [selectedOption, setSelectedOption] = React.useState<
    OptionsType | undefined
  >(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [selectedCompany, setSelectedCompany] = React.useState<any>(null)
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [isImportErrors, setIsImportErrors] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>('')
  const [debounceSearchText, setDebounceSearchText] = React.useState<string>('')
  const [isFileError, setIsFileError] = React.useState({
    isError: false,
    message: ''
  })
  const [errorsArray, setErrorsArray] = React.useState([])
  const [isDropdownError, setIsDropdownError] = React.useState({
    isError: false,
    message: ''
  })
  const [isShowMore, setIsShowMore] = React.useState<boolean>(false)
  const errorMessageDisplay = isShowMore ? errorsArray : errorsArray.slice(0, 3)

  const [registerDeviceApi, { isLoading, isSuccess, isError, error }] =
    useRegisterDeviceMutation()

  const {
    data,
    isFetching,
    refetch
  } = useCorporationListDeviceQuery({
    searchText: debounceSearchText,
    currentPageNumber: currentPage,
    rowLimitPerPage: 100
  })

  React.useEffect(() => {
    // if(searchText !== ''){
    //   const timer = setTimeout(() => {
    //     setDebounceSearchText(searchText)
    //     setOptions([])
    //   }, 500)
    //   return () => clearTimeout(timer)
    // }
    const timer = setTimeout(() => {
      setDebounceSearchText(searchText)
      if(searchText !== ''){
        setOptions([])
      }
    }, 500)
    return () => clearTimeout(timer)
    
  }, [searchText])

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedOption(undefined)
      setSelectedCompany(null)
      setSelectedFile(null)
      onClose()
      toast.success(t('deviceImport.ImportSuccessfullyDone'))
    }
  }, [isSuccess])

  React.useEffect(() => {
    if (isError) {
      if (error?.data?.result_code === 'WARN_INPUT_PARAM') {
        if (error?.data?.errors?.length > 0) {
          const errors = error?.data?.errors
          // csv_file is the field name in the backend setIsFileError(true)
          const CSVError = errors?.find((item: any) => {
            return item.param === 'csv_file'
          })
          if (CSVError) {
            setIsFileError({
              isError: true,
              message: CSVError.message
            })
          }

          const dropdownError = errors?.find((item: any) => {
            return item.param === 'corp_id'
          })

          if (dropdownError) {
            setIsDropdownError({
              isError: true,
              message: dropdownError.message
            })
          }
        } else {
          toast.error(error?.data?.message)
        }
      } else if (error?.data?.result_code === 'WARN_INVALID_DATA') {
        const errors = error?.data?.errors
        const errorsArray = errors.map((item: any) => {
          return item.message
        })
        setErrorsArray(errorsArray)
        setIsImportErrors(true)
      } else {
        toast.error(error?.data?.message)
      }
    }
  }, [isError, error])

  React.useEffect(() => {
    if (data) {
      const items = mapKeysCamelCase(data?.data?.corporation_list)
      const optionsData = items.map((item: any) => {
        return {
          value: item.corpId,
          label: item.company + ': ' + item.mailAddress ?? ''
        }
      })
      let allOption = [...optionsData]

      if (
        data &&
        data?.data &&
        data?.data?.total_corporation_count &&
        data?.data?.total_corporation_count <= 100
      ) {
        setOptions(optionsData)
        return
      } else {
        allOption = [...options, ...optionsData]
      }

      const uniqueArray = allOption.filter((item: any, index: number) => {
        return (
          allOption.findIndex((item2: any) => {
            return item2.value === item.value
          }) === index
        )
      })

      setOptions(uniqueArray)
    }
  }, [data])

  const onInputChangeHandler = (value: string) => {
    setSearchText(encodeURIComponent(value))
    setCurrentPage(0)
  }
  const onCloseModal = () => {
    onClose()
    setSelectedOption(undefined)
    setSelectedCompany(null)
    setSelectedFile(null)
    setErrorsArray([])
    setIsImportErrors(false)
    setSearchText('')
    setDebounceSearchText('')
    setIsFileError({
      isError: false,
      message: ''
    })
  }

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append('csv_file', selectedFile as File)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    formData.append('corp_id', selectedCompany?.corpId ?? '')
    setSelectedFile(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (!selectedFile || !selectedCompany?.corpId) {
      if (!selectedFile) {
        setIsFileError({
          isError: true,
          message: t('deviceImport.PleaseUploadACSVFile')
        })
      }
      if (!selectedCompany?.corpId) {
        setIsDropdownError({
          isError: true,
          message: t('deviceImport.PleaseSelectCorporate')
        })
      }
    } else {
      await registerDeviceApi(formData)
    }
  }
  const fileChangeHandler = (file: File) => {
    setSelectedFile(file)
    setErrorsArray([])
    setIsFileError({
      isError: false,
      message: ''
    })
    setIsImportErrors(false)
  }

  const loadMore = async () => {
    if (data?.data?.total_corporation_count === options.length) return
    setCurrentPage(currentPage + 1)
    refetch()
  }

  const handleMenuScrollToBottom = async () => {
    if (!isFetching) await loadMore()
  }

  const optionChangeHandler = (option: OptionsType) => {
    setErrorsArray([])
    setIsDropdownError({
      isError: false,
      message: ''
    })
    setIsImportErrors(false)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const items = mapKeysCamelCase(data?.data?.corporation_list)
    // setErrorsArray([])

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const findOption = items.find(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (item: any) => item?.corpId === option.value
    )

    setSelectedCompany(findOption)
  }

  const onMenuCloseHandler = () => {
    setDebounceSearchText('')
    setCurrentPage(0)
  }

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  const CustomDialogTitle = ({ children, onClose }: CustomDialogTitleProps) => {
    // eslint-disable-next-line no-shadow
    const classes = useDeviceImportStyles()

    return (
      <DialogTitle>
        {children}
        <IconButton
          aria-label='close'
          onClick={onCloseModal}
          className={classes.iconBtn}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    )
  }

  // customize react-select
  // eslint-disable-next-line react/no-unstable-nested-components
  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <ArrowDown className={classes.selectArrow} />
    </components.DropdownIndicator>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option = (
    props: JSX.IntrinsicAttributes &
      OptionProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{props.children}</div>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.isSelected && <CheckIcon style={{minWidth: '20px'}} />}
    </components.Option>
  )

  // for react select medium start
  const customStylesMd = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      background: '#fff',
      minHeight: '38px',
      height: '38px',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0px 0px 1px #9AA5B1' : null,
      // eslint-disable-next-line no-nested-ternary
      border: state.isFocused
        ? '1px solid #9AA5B1'
        : isDropdownError.isError
        ? `1px solid #FF0000`
        : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#9AA5B1'
      },
      '&:focus': {
        borderColor: '#9AA5B1'
      }
    }),

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    valueContainer: (provided: any) => ({
      ...provided,
      height: '38px',
      padding: '0 16px',
      marginTop: '-1px'
    }),

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    input: (provided: any, state: unknown) => ({
      ...provided,
      margin: '0px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    indicatorSeparator: (state: unknown) => ({
      display: 'none'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    indicatorsContainer: (provided: any, state: unknown) => ({
      ...provided,
      height: '38px',
      marginTop: '-1px'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unused-vars
    menu: (provided: any, state: unknown) => ({
      ...provided,
      borderRadius: '4px'
      // boxShadow: '0px 3px 20px #00000029'
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    option: (provided: any, state: any) => ({
      ...provided,
      height: '37px',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundColor: state.isSelected ? '#FAFAFA' : '#fff',
      color: state.isSelected ? `${ptColors.ebonyClay}` : '#6C7778',
      ':active': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        backgroundColor: state.isSelected ? '#FAFAFA' : '#fff'
      },
      ':first-of-type': {
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px'
      },
      ':last-child': {
        borderBottomRightRadius: '4px',
        borderBottomLeftRadius: '4px'
      }
    })
  }

  // for react select medium end

  return (
    <Box>
      <DeviceDialog
        open={open}
        onClose={onCloseModal}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              background: 'rgba(255, 255, 255, 0.8)',
              zIndex: 5
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            >
              <Loader colorCode='#939598' />
            </Box>
          </Box>
        )}
        <CustomDialogTitle onClose={onCloseModal}>
          <Typography
            fontSize='18px'
            fontWeight='bold'
            lineHeight='26px'
            height='24px'
            color='#232F3E'
          >
            New Import
          </Typography>
        </CustomDialogTitle>
        <Box
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          className={classes.errorDialogBlock}
          style={{
            display: `${isImportErrors ? 'block' : 'none'}`,
            height: `${isShowMore ? '100%' : ''}`
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <Box className={`${classes.errorContentBlock}`}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <Box className={classes.errorTitleBlock}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <h3 className={classes.errorTitle}>Failed to import devices</h3>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setIsShowMore(false)
                  setIsImportErrors(false)
                }}
                className={classes.iconBtn}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <SimpleBarReact className={classes.errorBlock}>
              {errorMessageDisplay.map((item: string, key: number) => (
                <Box key={key} className={classes.errorItem}>
                  <Typography className={classes.errorLine}>
                    {item.split('-')[0]} -
                  </Typography>
                  <Typography className={classes.errorText}>
                    {/* {item.split('-')[1]} */}
                    {item.substring(item.indexOf('-')+1)}
                  </Typography>
                </Box>
              ))}
            </SimpleBarReact>

            {(errorMessageDisplay.length !== 0 && errorsArray.length > 3) &&
              (isShowMore ? (
                <Button
                  type='button'
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  className={classes.showMoreBlock}
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <Typography className={classes.showMoreText}>
                    Show Less
                  </Typography>
                  <ShowUpIcon />
                </Button>
              ) : (
                <Button
                  type='button'
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  className={classes.showMoreBlock}
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <Typography className={classes.showMoreText}>
                    Show More
                  </Typography>
                  <ShowMoreIcon />
                </Button>
              ))}
          </Box>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <SimpleBarReact className={classes.deviceImportSimplebar}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '6px',
                padding: '16px 0 0 0',
                marginBottom: '7px'
              }}
            >
              <Typography
                fontSize='14px'
                color='#232F3E'
                lineHeight='19px'
                letterSpacing='0.09px'
              >
                Corporate
              </Typography>
              <FromErrorMessage
                message={isDropdownError.message}
                isError={isDropdownError.isError}
              >
                <Select
                  placeholder='Search by Corporate ID/Email/Name'
                  value={selectedOption}
                  onChange={(option) =>
                    optionChangeHandler(option as OptionsType)
                  }
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  onInputChange={onInputChangeHandler}
                  onMenuClose={onMenuCloseHandler}
                  options={options}
                  isLoading={isFetching}
                  components={{ DropdownIndicator, Option }}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  className={classes.reactSelect}
                  classNamePrefix='react-select'
                  styles={customStylesMd}
                  filterOption={() => true}
                />
              </FromErrorMessage>
            </Box>
            <CustomizedAccordions title='Instruction'>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' height='16px'>
                  Register IMEI all at once.
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' height='16px'>
                  Please upload a csv file that only specifies IMEI and model
                  name.
                </Typography>
              </ListItemText>
            </CustomizedAccordions>
            {selectedCompany && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '56% 40%',
                  gridGap: '21px',
                  marginTop: '10px',
                  marginBottom: '7px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    rowGap: '7px'
                  }}
                >
                  <Typography fontSize='14px' fontWeight='bold'>
                    Corporate ID
                  </Typography>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  <Box className={classes.corporateInfo}>
                    {selectedCompany?.corpId}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    rowGap: '7px'
                  }}
                >
                  <Typography fontSize='14px' fontWeight='bold'>
                    Company Name
                  </Typography>
                  <Box
                    sx={{
                      width: '206px',
                      // textOverflow: 'ellipsis',
                      // overflow: 'hidden',
                      // whiteSpace: 'nowrap'
                    }}
                    className={classes.corporateInfo}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    
                    <CustomTooltip
                      toolTipTitle={selectedCompany?.company}
                      maxWidth={200}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    rowGap: '7px'
                  }}
                >
                  <Typography fontSize='14px' fontWeight='bold'>
                    Telephone
                  </Typography>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  <Box className={classes.corporateInfo}>
                    {selectedCompany?.telephone ? selectedCompany?.telephone : '-'}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    rowGap: '7px'
                  }}
                >
                  <Typography fontSize='14px' fontWeight='bold'>
                    Location
                  </Typography>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                  <Box className={classes.corporateInfo}>
                    {selectedCompany?.location ? (
                      <CustomTooltip
                        toolTipTitle={selectedCompany?.location}
                        maxWidth={200}
                      />
                    ) : '-'}
                    
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    rowGap: '7px'
                  }}
                >
                  <Typography fontSize='14px' fontWeight='bold'>
                    Email Address
                  </Typography>
                  <Box className={classes.corporateInfo}>
                    <CustomTooltip
                      toolTipTitle={selectedCompany?.mailAddress}
                      maxWidth={250}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '6px',
                padding: '14px 0 0 0',
                marginBottom: '7px'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Typography fontSize='14px' lineHeight='19px'>
                  IMEI List
                </Typography>
                <Typography fontSize='12px' color='#6C7778' lineHeight='19px'>
                  Less than 500 lines, if exceeding, please split the file
                </Typography>
              </Box>
              <FileInput
                onChange={(file) => fileChangeHandler(file as File)}
                placeholder='Browse...'
                acceptedFileTypes='.csv'
                errorText={isFileError.message}
                isError={isFileError.isError}
                fileNameText={selectedFile ? selectedFile.name : ''}
              />
            </Box>
            <CustomizedAccordions title='Instruction'>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='18px'>
                  * You can not specify files other than CSV format and UTF-8 encoding files.
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  * Header row cannot be specified
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  * When registering multiple IMEIs, separate them with line
                  breaks.
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  * Set a comma as the delimiter between IMEI and model name.
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  * Please set the model name as a numerical value as follows.
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  1 → POCKETALKW
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='20px'>
                  2 → POCKETALKS
                </Typography>
              </ListItemText>
              <ListItemText>
                <Typography fontSize='12px' color='#6C7778' lineHeight='18px'>
                  3 → POCKETALKSPlus
                </Typography>
              </ListItemText>
            </CustomizedAccordions>
          </SimpleBarReact>
        </DialogContent>
        <Box sx={{ padding: '0 32px' }}>
          <Divider sx={{ borderColor: '#DDDDDD', opacity: 1 }} />
        </Box>
        <DialogActions className={classes.btnBox}>
          <Button
            variant='outlined'
            className={classes.cancelBtn}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            startIcon={<DownloadIcon />}
            className={classes.importBtn}
            onClick={onSubmit}
          >
            Import
          </Button>
        </DialogActions>
      </DeviceDialog>
    </Box>
  )
}

export default DeviceImportDialog
