import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WEB_STORAGE } from '../../constants/constants';

/**
 * RMS認証成功後セッションから取得したログイン情報State
 */
type AuthInfoState = {
    name: string;
    givenName: string;
    familyName: string;
    picture: string;
    email: string;
    locale: string;
    authId: string;
    authName: string;
    accessToken: string;
    isLogin: boolean;
}

/**
 * 初期State
 */
const initialState: AuthInfoState = {
    name: '',
    givenName: '',
    familyName: '',
    picture: '',
    email: '',
    locale: '',
    authId: '',
    authName: '',
    accessToken: '',
    isLogin: false,
};

/**
 * RMS認証成功後セッションから取得したログイン情報 Slice
 */
const authInfoSlice = createSlice({
    name: WEB_STORAGE.AUTH_INFO,
    initialState,
    reducers: {
        saveName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        saveGivenName: (state, action: PayloadAction<string>) => {
            state.givenName = action.payload;
        },
        saveFamilyName: (state, action: PayloadAction<string>) => {
            state.familyName = action.payload;
        },
        savePicture: (state, action: PayloadAction<string>) => {
            state.picture = action.payload;
        },
        saveEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        saveLocale: (state, action: PayloadAction<string>) => {
            state.locale = action.payload;
        },
        saveAuthId: (state, action: PayloadAction<string>) => {
            state.authId = action.payload;
        },
        saveAuthName: (state, action: PayloadAction<string>) => {
            state.authName = action.payload;
        },
        saveAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        saveLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload;
        }

    },
    });

export { authInfoSlice };
export type { AuthInfoState };
