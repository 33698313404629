/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { API_REQUEST_CONFIG } from '../constants/constants'

/** ------------------------------------------------
 * API呼び出し Util
 ------------------------------------------------ */

/**
 * 対象がobjectか返す
 *
 * @param target 調査対象
 * @returns true：object / false: null or object以外
 */
const isObject = (target: any): target is object =>
  typeof target === 'object' && target !== null

/**
 * 参考：https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
 *
 * @param str スネークケースをキャメルケースに変更する
 * @returns スネークケースをキャメルケースに変換したもの
 */
const convertSnakeCaseToCamelCase = (str: string | undefined): string => {
  if (str === undefined) {
    return ''
  }
  const camelCaseStr = str
    .replace(/(_.)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/_/g, '')

  return camelCaseStr
}

/**
 * キーの値をスネークケースからキャメルケースに変換して返す
 *
 * @param data 変換対象のobject
 * @returns キーの値をスネークケースからキャメルケースに変換して返す
 */
const mapKeysToCamelCase = (data: unknown): Record<string, unknown> => {
  const obj = data as Record<string, unknown>

  const keyValues = Object.keys(obj).map((key) => {
    const newKey = convertSnakeCaseToCamelCase(key) || key

    return { [newKey]: obj[key] }
  })

  const newObject = keyValues.reduce((result, current) => {
    const keys = Object.keys(current)
    if (keys.length === 0) {
      return result
    }
    const key = keys[0]
    result[key] = current[key]

    return result
  }, {})

  return newObject
}

/**
 *
 * @param data
 * @param callback
 * @returns
 */
const mapValues = (data: unknown, callback: any): Record<string, unknown> => {
  const obj = data as Record<string, unknown>
  const keys = Object.keys(obj)
  const result: Record<string, unknown> = {}
  for (let i = 0, len = keys.length; i < len; i += 1) {
    result[keys[i]] = callback(obj[keys[i]], keys[i])
  }

  return result
}

/**
 *
 * @param data
 * @param callback
 * @returns
 */
const responseMapKeysDeep = (data: unknown, callback: any): unknown => {
  if (Array.isArray(data)) {
    return data.map((innerData) => responseMapKeysDeep(innerData, callback))
  }
  if (isObject(data)) {
    return mapValues(mapKeysToCamelCase(data), (val: unknown) =>
      responseMapKeysDeep(val, callback)
    )
  }

  return data
}

/**
 *
 * @param data
 * @returns
 */
export const mapKeysCamelCase = (data: any): any =>
  responseMapKeysDeep(data, (_value: any, key: string | undefined) =>
    convertSnakeCaseToCamelCase(key)
  )

/**
 *
 */
const baseURL = `${process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:3000'}`

const callApiUtil = axios.create({
  baseURL,
  timeout: API_REQUEST_CONFIG.TIMEOUT
})
callApiUtil.interceptors.response.use(
  (response) => {
    const { data } = response
    const convertedData = mapKeysCamelCase(data)

    return { ...response, data: convertedData }
  },
  (error) => Promise.reject(error)
)

export default callApiUtil
