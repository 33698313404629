/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IDeviceListResponse,
  deviceSearchParamType
} from '../../../types/device'
import apiSlice from '../../api/apiSlice'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['NotificationHistoryList']
})

export const notificationSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    notificationList: builder.query<any, any>({
      query: ({
        searchText,
        currentPageNumber,
        rowLimitPerPage,
        colName,
        sortDirection
      }: any) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `/private/notification-list?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}`,
        // url: `http://localhost:8000/private/notification-list?searchText=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${colName}&sortDirection=${sortDirection}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['NotificationHistoryList']
    }),
    createNotification: builder.mutation<any, any>({
      query: (body) => ({
        url: '/private/create-notification',
        // url: 'http://localhost:8000/private/create-notification',
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result) => (result ? ['NotificationHistoryList'] : [])
    }),
    deleteNotification: builder.mutation<any, any>({
      query: (body) => ({
        url: `/private/delete-notification`,
        method: 'DELETE',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['NotificationHistoryList']
    }),
    getNotificationbyId: builder.query<any, any>({
      query: ({
        notification_id
      }: any) => ({
        url: `/private/get-notification-detail?notification_id=${notification_id}`,
        // url: `http://localhost:8000/private/get-notification-detail?notification_id=${notification_id}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),

      providesTags: ['NotificationHistoryList']
    }),
    updateNotification: builder.mutation<any, any>({
      query: (body) => ({
        url: '/private/update-notification',
        // url: 'http://localhost:8000/private/update-notification',
        method: 'PUT',
        body: body
      }),
      invalidatesTags: (result) => (result ? ['NotificationHistoryList'] : [])
    }),
  })
})

export const {
  useLazyNotificationListQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationbyIdQuery,
  useUpdateNotificationMutation
} = notificationSlice
