import { makeStyles } from '@mui/styles';
import { ptColors } from '../../../themes/settings/colors';

const formErrorStyles = makeStyles(() => ({
  formError: {
    position: 'relative',
  },
  formError_message: {
    position: 'absolute',
    top: '-33px',
    right: 0,
    padding: '5px 10px',
    backgroundColor: ptColors.rossoCorsa,
    color: '#fff',
    borderRadius: '6px',
    boxShadow: `0px 3px 6px ${"#00000029"}`,
    fontSize: '12px',
    zIndex: "5",
    lineHeight: '16px',
    fontWeight: "300",
    '&::after': {
      content: "''",
      position: 'absolute',
      right: '19px',
      bottom: '-4px',
      borderColor: `${ptColors.rossoCorsa} transparent transparent transparent`,
      borderRadius: '100% 0px 0px 0px',
      width: '9px',
      height: '9px',
      backgroundColor: ptColors.rossoCorsa,
      transform: 'rotate(45deg)',
      zIndex: "10",
    },
  },
}))

export default formErrorStyles;
