/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../../constants/constants';

export interface googleResponseTypes  {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    locale: string;
}

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL.GOOGLE_AUTH_API,
});
 const googleAuthSlice = createApi({

    reducerPath: 'api',
    baseQuery: async (args, api, extraOptions) => {
      const result = await baseQuery(args, api, extraOptions);
      
        return result;
    },
    tagTypes: [],
    endpoints:() => ({}),
 })
const apiWithTags = googleAuthSlice.enhanceEndpoints({
  addTagTypes: [],
});
export const googleAuthApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    googleLogin: builder.mutation<googleResponseTypes, string>({
      query: header => ({
        url: "/userinfo",
            method: "GET",
            headers: {
                "Authorization": "Bearer " + header
            }
      }),
    }),
  }),
});

export const { useGoogleLoginMutation } = googleAuthApi;