/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Dialog } from '@mui/material'
import { ptColors } from '../../../themes/settings/colors'

const useDeviceImportStyles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    padding: '8px',
    right: 24,
    top: 24,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: '14px'
  },

  loaderParent: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255, 0.7)',
    width: '100%',
    height: '100%',
    zIndex: 1
  },

  cancelBtn: {
    color: '#232F3E',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #DDDDDD',
    height: '44px',
    padding: '10px 19px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FAFAFA',
      border: '1px solid #D1D1D1',
      color: '#000'
    },
    '&:focus': {
      outline: '4px solid #CCEBF9',
      color: '#000'
    }
  },
  btnBox: {
    padding: '12px 32px 0 32px !important',
    display: 'flex',
    justifyContent: 'flex-end !important',
    alignItems: 'center',
    columnGap: '7px'
  },

  importBtn: {
    color: '#ffffff',
    fontWeight: '500',
    backgroundColor: '#007EB4',
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #0078AB',
    height: '44px',
    padding: '10px 20px',
    borderRadius: '4px',
    margin: '0 0 0 7px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#006D9C',
      border: '1px solid #0078AB'
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '6px'
    },
    '&:focus': {
      outline: '5px solid #CCEBF9'
    }
  },

  dialogContent: {
    borderTop: 'none',
    padding: '0',
    overflow: 'unset',
  },
  errorDialogBlock: {
    position: 'absolute',
    backgroundColor: `${ptColors.roseWhite}`,
    width: 580,
    borderRadius: 10,
    zIndex: 1,
    top: 0,
    left: 0
  },
  errorContentBlock: {
    padding: '26px 0 13px 32px',
    height: '100%'
  },
  errorTitleBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '24px'
  },
  icon: {
    cursor: 'pointer'
  },
  errorTitle: {
    fontSize: 18,
    marginBottom: 0,
    color: `${ptColors.ebonyClay}`,
    fontWeight: 700,
    height: '24px',
    lineHeight: '26px',
    marginTop: 0
  },
  errorBlock: {
    paddingTop: 5,
    paddingBottom: 2,
    height: 'calc(100% - 60px)',
    paddingRight: '32px'
  },
  errorItem: {
    display: 'flex',
    marginBottom: 7,
    '& .MuiTypography-root': {
      lineHeight: '18px',
      fontWeight: '400'
    },
  },
  errorLine: {
    color: `${ptColors.rossoCorsa}`,
    fontWeight: 500,
    fontSize: 13,
    minWidth: '78px'
  },
  errorText: {
    fontWeight: 500,
    fontSize: 13,
    marginLeft: '5px !important',
    textAlign: 'justify'
  },
  showMoreBlock: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    background: 'none',
    border: 'none',
    padding: '2px 0',
    textTransform: 'none',
    '&:hover': {
      background: 'none'
    },
    '&.Mui-focusVisible .MuiTypography-root': {
      color: '#a90000 !important',
    },
    '&.Mui-focusVisible svg path': {
      stroke: '#a90000 !important',
    }
  },
  showMoreText: {
    fontSize: "13px",
    fontWeight: 400,
    color: `${ptColors.rossoCorsa}`,
    marginRight: 10,
  },

  // customize react select option list start
  reactSelect: {
    '& .react-select__single-value': {
      color: '#232F3E'
    },
    '& .react-select__control--menu-is-open': {
      '& .react-select__single-value': {
        color: '#6C7778'
      },
    },
    '& .react-select__placeholder': {
      color: '#6C7778'
    },
    '& .react-select__indicator': {
      paddingRight: '12px'  
    },
    '& .react-select__menu': {
      margin: '0',
      boxShadow: '0px 3px 20px #00000029',
      zIndex: 1000,
      '& .react-select__menu-list': {
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '148px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '& .react-select__option': {
          padding: '10px 12px',
          width: '100%',
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          // whiteSpace: 'nowrap',
          // overflow: 'hidden',
          '&:hover': {
            backgroundColor: '#fafafa',
            color: '#232F3E',
          },
        },
        '& .react-select__option:not(:last-child)': {
          borderBottom: '0.5px solid #ddd',
        },
      },
    },
  },
  selectArrow: {
    marginTop: '2px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5,
    },
  },
  deviceImportSimplebar: {
    minHeight: '220px',
    maxHeight: '500px',
    padding: '0 32px 13px',
  },
  corporateInfo: {
    fontSize: '14px',
    '& div': {
      lineHeight: 'unset',
      height: 'unset'
    }
  },
}))

const DeviceDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    width: '580px',
    borderRadius: '10px',
    maxWidth: 'unset !important',
    padding: '26px 0 30px',
    position: 'relative',
    overflow: 'hidden',
    margin: 0
  },
  // '& .MuiDialogContent-root': {
  //   overflowY: 'unset'
  // },
  '& .MuiDialogTitle-root': {
    padding: '0 32px'
  },
  '& .MuiDialogActions-root': {
    padding: '0 32px',
    justifyContent: 'left'
  },
  '& .MuiDialog-container': {
    height: 'unset !important',
    marginTop: '100px',
    '& :not(:first-of-type)': {
      marginLeft: '0'
    },
    '@media screen and (max-width: 1366px)': {
      marginTop: '25px',
    },
  },
}))

export { useDeviceImportStyles, DeviceDialog }
