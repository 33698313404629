/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, MouseEvent, useRef } from 'react'
import * as Material from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ptColors } from '../../../themes/settings/colors'

export type TooltipProps = {
  toolTipTitle?: string | number
  maxWidth?: number
}

const MultiLineTooltip: React.FC<TooltipProps> = (props) => {
  const useStyles = makeStyles(() => ({
    toolTipContent: {
      maxWidth: `${props.maxWidth ?? ''}px`,
      textOverflow: 'ellipsis',
      color: `${ptColors.ebonyClay}`,
      fontFamily: "'Noto Sans JP', sans-serif",
      whiteSpace: 'normal',
      height: 'inherit',
      display: 'block',
      wordWrap: 'break-word',
      overflow: 'hidden',
      maxHeight: '3.6em',
      lineHeight: '22px',
      width: 'inherit'
    },
    whiteSpaceAllow: {
      whiteSpace: 'pre-wrap'
    }
  }))

  const classes = useStyles()

  const [tooltipEnabledGroup, setTooltipEnabledGroup] = useState(false)
  const tooltipRef = useRef<any>(null);
  const stringLength = 65

  const handleShouldShowTooltip = (event: MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const textLength = element?.textContent?.length ? element?.textContent?.length : 0
    const clientWidth = element.getBoundingClientRect().width;

    // Clone the element
    const clone = element.cloneNode(true) as HTMLElement;

    // Set styles for the clone
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';

    // Append the clone to the DOM
    element.parentNode?.appendChild(clone);

    // Set the element's overflow to visible
    element.style.overflow = 'visible';

    // Get the scroll width of the clone
    const scrollWidth = clone.getBoundingClientRect().width;

    // Remove the clone from the DOM
    element.parentNode?.removeChild(clone);

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';

    if ((scrollWidth > clientWidth) && (textLength > stringLength)) {
      setTooltipEnabledGroup(true);
    } else {
      setTooltipEnabledGroup(false);
    }
  };

  const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const getClippedText = (text: string | number) => {
    if(text.toString().length < stringLength){
        return text
    }
    
    return `${text.toString().substring(0, stringLength)}...`
  }

  return (
    <Material.Tooltip
      placement='top'
      title={props.toolTipTitle ?? ''}
      onMouseEnter={handleShouldShowTooltip}
      disableHoverListener={!tooltipEnabledGroup}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        onClick: handleTooltipClick,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7]
            }
          }
        ]
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: '240px',
            padding: '8px 15px 9px',
            textAlign: 'center',
            fontWeight: 300,
            fontSize: '12px',
            backgroundColor: '#37475A',
            lineHeight: '17px',
            whiteSpace: 'pre-wrap'
          }
        }
      }}
    >
      <div className={`${classes.toolTipContent} ${classes.whiteSpaceAllow}`} ref={tooltipRef}>
        {getClippedText(props && props.toolTipTitle ? props.toolTipTitle : "")}
      </div>
    </Material.Tooltip>
  )
}

export default MultiLineTooltip