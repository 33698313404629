/* eslint-disable */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../Modecules/Header';
import { ReactComponent as VerifyIcon } from '../../images/icon/verified.svg';
import { ReactComponent as ErrorIcon } from '../../images/icon/expired.svg';
import { useStyles } from '../../themes';
import { Link, useLocation } from 'react-router-dom';
import { useVerifyBOOneTimeKeyQuery } from '../../state/features/user/userSlice';
import Loader from '../Atoms/Loader/Loader';


const Verify = (): JSX.Element => {
  const classes = useStyles();
  const [isVerified, setIsVerified] = React.useState<boolean>();
  const [message, setMessage] = React.useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const oneTimeKey = queryParams.get('onetimekey');
  const { isSuccess, data, error, isLoading } = useVerifyBOOneTimeKeyQuery({
    onetimekey: oneTimeKey
  });
  React.useEffect(() => {
    if (!oneTimeKey) {
      setIsVerified(false);
      setMessage('Your invitation link is invalid.');
    }
  }, []);

  React.useEffect(() => {
    if (isSuccess) {
      setMessage(data?.message);
      setIsVerified(true);
    } else if (error) {
      setIsVerified(false);
      setMessage(error?.data?.message);
    }
  }, [isSuccess, error]);

  return (
    <>
      <Header />
      {isLoading ? (
        <Box className={classes.verify__loading}>
          <Box className={classes.verify__loadingcontent}>
              <Loader colorCode="#939598" />
            <Typography fontSize="12px" fontWeight="300">
              Account activation is processing...
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={classes.verify}>
          <Box className={classes.verify__card}>
            {isVerified ? (
              // if verified
              <Box>
                <VerifyIcon />
                <Typography className={classes.verify__text}>
                  <span className={classes.verify__textSuccess}>
                    {message}
                  </span>{' '}
                  <br /> <span> You can now login.</span>
                </Typography>
                <Link to="/login" className={classes.verify__btn}>
                  Back to Login Page
                </Link>
              </Box>
            ) : (
              // if not verified
              <Box>
                <ErrorIcon />
                <Typography className={classes.verify__text}>
                  <span className={classes.verify__textError}>
                    {message}
                  </span>
                  <br />
                  <span>
                    Please communicate with administrator <br /> for new
                    invitation.
                  </span>
                </Typography>
                {/* <Link className={classes.verify__btn} to='/'> Back to Home Page</Link> */}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Verify;
