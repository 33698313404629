/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@mui/styles';
import { ptColors } from '../../../themes/settings/colors';

export const useStyles = makeStyles(() => ({
  tableHeader: {
    whiteSpace: 'pre',
    backgroundColor: `${ptColors.alabaster}`,
    height: 47,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: "14px 0",
    display: "flex",
    borderBottom: '1px solid #dddddd',
    '& div:hover': {
      visibility: 'visible',
      opacity: 0.85,
      marginBottom: 0,
    },
    '& .headerItem:first-child': {
      paddingLeft: '20px',
    },
    // '& .headerItem:last-child': {
    //   textAlign: 'right',
    //   paddingRight: '35px'
    // },
  },
  tableHeaderJustifyBetween: {
    justifyContent: 'space-between'
  },
  tableHeaderTitle: {
    position: 'relative',
    width: '100%'
  },
  tableHeaderTitleText: {
    display: 'inline-block',
    color: `${ptColors.ebonyClay}`,
    opacity: 1,
    height: 19,
    marginLeft: 0,
    fontFamily: "Noto Sans JP",
    fontSize: 14,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableHeaderIcon: {
    position: 'absolute',
    top: 7,
    display: ' inline-block',
    marginLeft: 'auto',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableHeaderIconContainer: {
    height: '11.5px',
    marginLeft: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
}));
