/* eslint-disable @typescript-eslint/no-empty-function */
import { ICalendarProps } from "../../../../types/datepicker/index";

const Calendar: React.FC<ICalendarProps> = ({ 
  dateObj, 
  currentDateObj, 
  onDateClick,
  min = null,
  max = null,
}) => {
  const daysInMonth = new Date(dateObj.year, dateObj.month+1, 0).getDate();
  const startDay = new Date(dateObj.year, dateObj.month, 0).getDay();

  const getStartEmptyCells = ():JSX.Element[] => {
    const emptyCells = [];
    for (let i = 0; i < startDay; i+=1) {
      emptyCells.push(<div key={i} className="datepicker_modal_calendar_date_day-empty" />);
    }

    return emptyCells;
  };

  const getDateCells = () => {
    const dateCells = [];
    for (let i = 1; i <= daysInMonth; i+=1) {
      let dayClass = "datepicker_modal_calendar_date_day-normal";
      if (dateObj.date === i) {
        dayClass = "datepicker_modal_calendar_date_day-selected";
      } else if (
        currentDateObj.date === i &&
        currentDateObj.month === dateObj.month &&
        currentDateObj.year === dateObj.year
      ) {
        dayClass = "datepicker_modal_calendar_date_day-current";
      }
      if (dateObj.date === i) {
        dayClass = "datepicker_modal_calendar_date_day-selected";
      }
      if (min && new Date(`${dateObj.year}-${dateObj.month + 1}-${i}`).getTime() < new Date(min).getTime() -86400000 ) {
        dayClass = "datepicker_modal_calendar_date_day-disabled";
      }
      if (max && new Date(`${dateObj.year}-${dateObj.month + 1}-${i}`) > max ) {
        dayClass = "datepicker_modal_calendar_date_day-disabled";
      }
      dateCells.push(
        <button type="button"
          key={i}
          onClick={
            (min && new Date(`${dateObj.year}-${dateObj.month + 1}-${i}`).getTime() < new Date(min).getTime() -86400000 ) || 
            (max && new Date(`${dateObj.year}-${dateObj.month + 1}-${i}`) > max )
              ? () => {}
              : () => {
                  if(min && max){
                    onDateClick(dateObj.year, dateObj.month, i, dateObj.hours, dateObj.minutes);
                  }
                  if(min && !max){
                    onDateClick(dateObj.year, dateObj.month, i, new Date().getHours(), new Date().getMinutes());
                  }
                  if(!min && max){
                    onDateClick(dateObj.year, dateObj.month, i, dateObj.hours, dateObj.minutes);
                  }
                  if(!min && !max){
                    onDateClick(dateObj.year, dateObj.month, i, dateObj.hours, dateObj.minutes);
                  }
                }
          }
          className={dayClass}
        >
          {i}
        </button>
      );
    }
    
    return dateCells;
  };

  return (
    <div className="datepicker_modal_calendar">
      <div className="datepicker_modal_calendar_week">
        <div className="datepicker_modal_calendar_week_day">MON</div>
        <div className="datepicker_modal_calendar_week_day">TUE</div>
        <div className="datepicker_modal_calendar_week_day">WED</div>
        <div className="datepicker_modal_calendar_week_day">THU</div>
        <div className="datepicker_modal_calendar_week_day">FRI</div>
        <div className="datepicker_modal_calendar_week_day">SAT</div>
        <div className="datepicker_modal_calendar_week_day">SUN</div>
      </div>
      <div className="datepicker_modal_calendar_date">
        {getStartEmptyCells()}
        {getDateCells()}
      </div>
    </div>
  );
};

export default Calendar;
