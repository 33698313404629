// import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography } from '@mui/material';
import { useStyles } from '../../themes/index';
// hooks
// import useLoginInfo from '../../hooks/useLoginInfo';

// assets
import { ReactComponent as Icon } from '../../images/logo.svg';

// components
import Account from '../Atoms/Account';
import useAuthHook from '../../hooks/useAuth';
/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
const Header = (): JSX.Element => {
  // 共通スタイル
  const classes = useStyles();

  const { userInfo: loginInfo, isRegisted } = useAuthHook();


  return (
    <div className={classes.appBar}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar className={classes.appbarToolbar}>
          <Link className={classes.logo} to="/">
            <Icon />
          </Link>
          {isRegisted() && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: '7px',
              }}
            >
              <Typography noWrap className={classes.accountName}>
                {loginInfo?.name}
              </Typography>
              <Account name={loginInfo?.name} picture={loginInfo?.picture} />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
