import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Dialog } from '@mui/material'

const emailPreviewDialogStyles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    padding: '8px',
    right: 24,
    top: 24,
    "&:focus":{
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: '14px',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions_form: {
    width: '100%'
  },
  actions_row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  actions_row_cell: {
    width: '357px'
  },
  actions_row_divider: {
    width: '100%',
    background: '#D9D9D9',
    height: '1px',
    opacity: 1,
    marginTop: '20px'
  },
  actions_row_buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  loaderParent: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255, 0.7)',
    width: '100%',
    height: '100%',
    zIndex: 1
  },
  emailPreviewBar: {
    backgroundColor: '#009DE1',
    height: '80px',
    padding: '18px 32px 25px',
  },
  dialogContentWrapper: {
    maxHeight: '487px',
    padding: '0 32px'
  },
  dialogContentBody: {
    padding: '32px'
  },
  dialogContentBodyBell: {
    padding: '10px 0'
  },
  dialogBodyTitle: {
    wordBreak: "break-all",
    color: '#313133',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'medium',
    whiteSpace: 'pre-wrap'
    // textTransform: 'capitalize'
  },
  dialogBodyDate: {
    color: '#313133',
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '23px'
  },
  dialogBodyText: {
    wordBreak: "break-all",
    color: '#313133',
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '23px',
    whiteSpace: 'pre-wrap',
    '& p': {
      margin: 0
    }
  },
  dialogBodyRegards: {
    color: '#313133',
    fontSize: '16px',
    lineHeight: '26px',
    marginTop: '23px'
  },

  // bell inner card
  dialogBodyCard: {
    width: '453px',
    boxShadow: '0 0 5px #00000029',
    backgroundColor: '#fff',
    padding: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    borderRadius: '8px',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '11px',
      height: '11px',
      backgroundColor: 'white',
      top: '-5px',
      right: '50px',
      transform: 'rotate(45deg)',
      boxShadow: '-3px -3px 5px -2px #00000029',
    }
  },
  dialogCardHeader:{
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    columnGap: '7px'
  },
  dialogCardHeaderText: {
    color: '#939598',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '300'
  },
  toggleBtn: {
    width: '40px',
    height: '18px',
    borderRadius: '20px',
    backgroundColor: '#D9D9D9',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '18px',
      height: '18px',
      backgroundColor: 'white',
      boxShadow: '0px 1px 3px #0000001A',
      border: '1px solid #DEDFDF',
      borderRadius: '50%'
    }
  },
  dialogCardBody: {
    paddingTop: '21px'
  },
  dialogTab: {
    borderBottom: '0.5px solid #d9d9d9',
    display: 'flex'
  },
  dialogTabItem: {
    // borderBottom: '.5px solid #d9d9d9',
    padding: '0 20px 11px',
    color: '#939598',
    fontSize: '13px',
    lineHeight: '18px'
  },
  dialogTabItemActive: {
    borderBottom: '2px solid #009DE1',
    padding: '0 20px 11px',
    // marginBottom: '-1px',
    color: '#009DE1',
    fontSize: '13px',
    lineHeight: '18px'
  },
  dialogCardContent: {
    paddingTop: '22px'
  },
  dialogCardTitle: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
    color: '#313133',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },
  dialogCardText: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#313133',
    marginTop: '6px',
    wordBreak: 'break-all',
    maxHeight: "38px",
    overflow: "hidden",
    whiteSpace: 'pre-wrap',
    '& p': {
      margin: 0
    },
  },
  dialogCardDate: {
    display: "flex", 
    alignItems: "center",
    height: "unset"
  },
  viewText: {
    color: '#009DE1',
    fontSize: '10px',
    lineHeight: '16px',
    marginLeft: '8px',
    backgroundColor: "transparent",
    border: "none",
    padding: '0',
    cursor: "pointer",
    display: "flex", 
    alignItems: "center",
    columnGap: "3px"
  },
  dialogCardSkeleton: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  // dialogCardSkeletonItem: {
  //   marginTop: '24px'
  // }
  skeletonMd: {
    backgroundColor: '#F6F6F6',
    height: '18px',
    borderRadius: '4px'
  },
  skeletonLg: {
    backgroundColor: '#F6F6F6',
    height: '37px',
    borderRadius: '4px'
  },
  skeletonSm: {
    backgroundColor: '#F6F6F6',
    width: '135px',
    height: '12px',
    borderRadius: '4px'
  }
}))

const CustomEmailPreviewDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    marginTop: '27px'
  },
  '& .MuiPaper-root': {
    width: '780px',
    borderRadius: '8px',
    maxWidth: 'unset !important',
    padding: '32px 0'
  },
  '& .MuiDialogTitle-root': {
    padding: 0
  },
  '& .MuiDialogActions-root': {
    paddingRight: '32px',
  },
}))

export { emailPreviewDialogStyles, CustomEmailPreviewDialog }
